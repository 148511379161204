import { create } from 'zustand';
import { ResponseGroupBookingDetail, SubGroup } from 'services/api/type/group.type';

interface PropsModal {
  title: string;
  okText?: string;
  cancelText?: string;
  onOk: (params?: any) => void;
}

export interface ModalType {
  isOpenAssignRoom: boolean;
  setIsOpenAssignRoom: (isOpen: boolean) => void;

  isOpenChangeRoomType: boolean;
  setIsOpenChangeRoomType: (isOpen: boolean) => void;

  isOpenCancelAssignRoom: boolean;
  setIsOpenCancelAssignRoom: (isOpen: boolean) => void;

  isOpenCancelRoom: boolean;
  setIsOpenCancelRoom: (isOpen: boolean) => void;

  isOpenUndoCancelledRoom: boolean;
  setIsOpenUndoCancelledRoom: (isOpen: boolean) => void;

  isOpenConfirmModal: boolean;
  confirmLoading?: boolean;
  propsConfirmModal: PropsModal | undefined;
  setInfoConfirmModal: (isOpen: boolean, propsConfirmModal?: PropsModal | undefined) => void;
  setConfirmLoading: (loading: boolean) => void;

  isOpenCustomer: boolean;
  setIsOpenCustomer: (isOpen: boolean) => void;

  isOpenAddService: boolean;
  setIsOpenAddService: (isOpen: boolean) => void;

  isOpenChangeRoom: boolean;
  setIsOpenChangeRoom: (isOpen: boolean) => void;

  isOpenChangeDate: boolean;
  setIsOpenChangeDate: (isOpen: boolean) => void;

  isOpenChangePrice: boolean;
  setIsOpenChangePrice: (isOpen: boolean) => void;
  isOpenChangePriceGroup: boolean;
  setIsOpenChangePriceGroup: (isOpen: boolean) => void;

  isOpenViewTrackingLogs: boolean;
  setIsOpenViewTrackingLogs: (isOpen: boolean) => void;

  isOpenBookingDetail: boolean;
  bookingLineId?: number;
  setOpenBookingDetail: (isOpen: boolean, bookingLineId?: number) => void;
  setBookingLineId: (bookingLineId?: number) => void;

  isOpenViewPDF: boolean;
  setIsOpenViewPDF: (isOpen: boolean) => void;

  isOpenViewPrintInvoiceV2: boolean;
  setIsOpenViewPrintInvoiceV2: (isOpen: boolean) => void;

  isOpenViewGroupInvoice: boolean;
  group_id: number | undefined;
  setIsOpenViewGroupInvoice: (isOpen: boolean, group_id?: number | undefined) => void;

  isOpenViewPDFGroup: boolean;
  setIsOpenViewPDFGroup: (isOpen: boolean) => void;

  isOpenConfirmationPDF: boolean;
  setIsOpenConfirmationPDF: (isOpen: boolean) => void;

  isOpenFixRoom: boolean;
  isCreateFixRoom: boolean;
  setIsOpenFixRoom: (isOpen: boolean, isCreateFixRoom?: boolean) => void;

  paymentId: number | undefined;
  isOpenConfirmDeletePayment: boolean;
  setIsOpenConfirmDeletePayment: (isOpen: boolean, paymentId?: number) => void;

  transactionId: number | undefined;
  isOpenConfirmDeleteExpenseTransaction: boolean;
  setIsOpenConfirmDeleteExpenseTransaction: (isOpen: boolean, transactionId?: number) => void;

  amount: number | undefined;
  state: string | undefined;
  isOpenViewChargeDebtPayment: boolean;
  setIsOpenViewChargeDebtPayment: (
    isOpen: boolean,
    paymentId?: number,
    amount?: number,
    state?: string
  ) => void;

  isOpenAddExpense: boolean;
  setIsOpenAddExpense: (isOpen: boolean) => void;

  isOpenAddExpensesTransactionV2: boolean;
  setIsOpenAddExpensesTransactionV2: (isOpen: boolean) => void;

  isOpenResolveDocument: boolean;
  document: any;
  setIsOpenResolveDocument: (isOpen: boolean, document?: any) => void;

  isOpenSetBreakfast: boolean;
  setIsOpenSetBreakfast: (isOpen: boolean) => void;

  isOpenUndoCheckIn: boolean;
  setIsOpenUndoCheckIn: (isOpen: boolean) => void;

  isOpenUndoCheckOut: boolean;
  setIsOpenUndoCheckOut: (isOpen: boolean) => void;

  isOpenSignature: boolean;
  propsSignatureModal: PropsModal | undefined;
  setIsOpenSignature: (isOpen: boolean, propsModal?: PropsModal | undefined) => void;

  isOpenChangePassword: boolean;
  setIsOpenChangePassword: (isOpen: boolean) => void;

  isOpenPayment: boolean;
  setIsOpenPayment: (isOpen: boolean) => void;

  isOpenCreateTravelAgency: boolean;
  setIsOpenCreateTravelAgency: (isOpen: boolean) => void;

  isOpenUpdateAndDeleteTravelAgency: boolean;
  setIsOpenUpdateAndDeleteTravelAgency: (isOpen: boolean) => void;

  isOpenGroupRoomingList: boolean;
  groupRoomingData: any;
  setIsOpenGroupRoomingList: (isOpen: boolean, groupRoomingData?: any) => void;

  isOpenSubGroupDetail: boolean;
  subGroupDetail: SubGroup | undefined;
  setIsOpenSubGroupDetail: (isOpenSubGroupDetail: boolean) => void;
  setSubGroupDetail: (subGroupDetail: SubGroup | undefined) => void;

  isOpenChangeDateGroupBooking: boolean;
  setIsOpenChangeDateGroupBooking: (isOpen: boolean) => void;

  isOpenCheckRoomAvailability: boolean;
  setIsOpenCheckRoomAvailability: (isOpen: boolean) => void;

  isOpenFocDetails: boolean;
  setIsOpenFocDetails: (isOpen: boolean) => void;

  isPDFOpen: boolean;
  setIsPDFOpen: (isPDFOpen: boolean) => void;

  postRoomCharge: {
    bookingLineId?: number;
    isOpenPostRoomCharge: boolean;
  };
  openPostRoomCharge: (bookingLineId: number) => void;
  closePostRoomCharge: () => void;

  postDummyRoomCharge: {
    dataGroup?: ResponseGroupBookingDetail | undefined;
    isOpenPostDummyRoomCharge: boolean;
  };
  openPostDummyRoomCharge: (dataGroup: ResponseGroupBookingDetail | undefined) => void;
  closePostDummyRoomCharge: () => void;

  moveTransaction: {
    transaction_ids: number[];
    bookingLineId?: number;
    code?: string;
    guest_id?: number;
    isOpenMoveTransaction: boolean;
  };
  openMoveTransaction: (
    transaction_ids: any,
    bookingLineId: number,
    code: string,
    guest_id: number
  ) => void;
  closeMoveTransaction: () => void;

  moveSaleOrder: {
    bookingLineId?: number;
    guest_id?: number;
    guest_order_id: number;
    isOpenMoveSaleOrder: boolean;
  };
  openMoveSaleOrder: (bookingLineId: number, guest_id: number, guest_order_id: number) => void;
  closeMoveSaleOrder: () => void;

  isOpenTransactionSearchBooking: boolean;
  setIsOpenTransactionSearchBooking: (isOpenSearchBooking: boolean) => void;

  postPaymentTransaction: {
    folio_balance_code?: string;
    booking_line_id?: number;
    guest_id?: number;
    orderId?: number;
    isOpenPostPaymentTransaction: boolean;
  };
  openPostPaymentTransaction: (
    folio_balance_code: string,
    booking_line_id: number,
    guest_id: number,
    orderId: number
  ) => void;
  closePostPaymentTransaction: () => void;

  postRefundTransaction: {
    folio_balance_code?: string;
    booking_line_id?: number;
    guest_id?: number;
    orderId?: number;
    isOpenPostRefundTransaction: boolean;
  };
  openPostRefundTransaction: (
    folio_balance_code: string,
    booking_line_id: number,
    guest_id: number,
    orderId: number
  ) => void;
  closePostRefundTransaction: () => void;

  cancelTransactions: {
    transaction_ids: number[];
    isOpenCancelTransactions: boolean;
  };
  openCancelTransactions: (transaction_ids: any) => void;
  closeCancelTransaction: () => void;

  undoCancelledTransactions: {
    cancelled_transaction_ids: number[];
    isOpenUndoCancelledTransactions: boolean;
  };
  openUndoCancelledTransactions: (transaction_ids: number[]) => void;
  closeUndoCancelledTransactions: () => void;

  createInvoiceTransaction: {
    orders: any[];
    guest_id: number | undefined;
    code: string;
    booking_line_id: number;
    isOpenCreateInvoice: boolean;
  };
  openCreateInvoiceTransaction: (
    orders: any[],
    guest_id: number | undefined,
    code: string,
    booking_line_id: number,
    isOpenCreateInvoice: boolean
  ) => void;
  closeCreateInvoiceTransaction: () => void;

  isOpenAddDiscount: boolean;
  setIsOpenAddDiscount: (isOpenAddDiscount: boolean) => void;

  bookingLineOrderId: number | undefined;
  isOpenTransactionHistory: boolean;
  setIsOpenTransactionHistory: (
    isOpenTransactionHistory: boolean,
    bookingLineOrderId?: number | undefined
  ) => void;
}

const useModal = create<ModalType>(set => ({
  isOpenAssignRoom: false,
  setIsOpenAssignRoom: (isOpen: boolean) => set(() => ({ isOpenAssignRoom: isOpen })),

  isOpenChangeRoomType: false,
  setIsOpenChangeRoomType: (isOpen: boolean) => set(() => ({ isOpenChangeRoomType: isOpen })),

  isOpenCancelAssignRoom: false,
  setIsOpenCancelAssignRoom: (isOpen: boolean) => set(() => ({ isOpenCancelAssignRoom: isOpen })),

  isOpenCancelRoom: false,
  setIsOpenCancelRoom: (isOpen: boolean) => set(() => ({ isOpenCancelRoom: isOpen })),

  isOpenUndoCancelledRoom: false,
  setIsOpenUndoCancelledRoom: (isOpen: boolean) => set(() => ({ isOpenUndoCancelledRoom: isOpen })),

  isOpenViewTrackingLogs: false,
  setIsOpenViewTrackingLogs: (isOpen: boolean) => set(() => ({ isOpenViewTrackingLogs: isOpen })),

  isOpenConfirmModal: false,
  propsConfirmModal: undefined,
  setInfoConfirmModal: (isOpen: boolean, propsConfirmModal: PropsModal | undefined) =>
    set(() => ({
      isOpenConfirmModal: isOpen,
      propsConfirmModal
    })),
  setConfirmLoading: (loading: boolean) =>
    set(() => ({
      confirmLoading: loading
    })),

  isOpenCustomer: false,
  setIsOpenCustomer: (isOpen: boolean) => set(() => ({ isOpenCustomer: isOpen })),

  isOpenAddService: false,
  setIsOpenAddService: (isOpen: boolean) => set(() => ({ isOpenAddService: isOpen })),

  isOpenChangeRoom: false,
  setIsOpenChangeRoom: (isOpen: boolean) => set(() => ({ isOpenChangeRoom: isOpen })),

  isOpenChangeDate: false,
  setIsOpenChangeDate: (isOpen: boolean) => set(() => ({ isOpenChangeDate: isOpen })),

  isOpenChangePrice: false,
  setIsOpenChangePrice: (isOpen: boolean) => set(() => ({ isOpenChangePrice: isOpen })),
  isOpenChangePriceGroup: false,
  setIsOpenChangePriceGroup: (isOpen: boolean) => set(() => ({ isOpenChangePriceGroup: isOpen })),

  isOpenBookingDetail: false,
  setOpenBookingDetail: (isOpen: boolean, bookingLineId?: number) =>
    set(() => ({
      isOpenBookingDetail: isOpen,
      bookingLineId
    })),
  setBookingLineId: (bookingLineId?: number) => set(() => ({ bookingLineId })),

  isOpenViewPDF: false,
  setIsOpenViewPDF: (isOpen: boolean) => set(() => ({ isOpenViewPDF: isOpen })),

  isOpenViewPrintInvoiceV2: false,
  setIsOpenViewPrintInvoiceV2: (isOpen: boolean) =>
    set(() => ({ isOpenViewPrintInvoiceV2: isOpen })),

  isOpenViewGroupInvoice: false,
  group_id: undefined,
  setIsOpenViewGroupInvoice: (isOpen: boolean, group_id?: number | undefined) =>
    set(() => ({ isOpenViewGroupInvoice: isOpen, group_id })),

  isOpenViewPDFGroup: false,
  setIsOpenViewPDFGroup: (isOpen: boolean) => set(() => ({ isOpenViewPDFGroup: isOpen })),

  isOpenConfirmationPDF: false,
  setIsOpenConfirmationPDF: (isOpen: boolean) => set(() => ({ isOpenConfirmationPDF: isOpen })),

  isOpenFixRoom: false,
  isCreateFixRoom: false,
  setIsOpenFixRoom: (isOpen: boolean, isCreateFixRoom?: boolean) =>
    set(() => ({ isOpenFixRoom: isOpen, isCreateFixRoom })),

  paymentId: undefined,
  isOpenConfirmDeletePayment: false,
  setIsOpenConfirmDeletePayment: (isOpen: boolean, paymentId?: number) =>
    set(() => ({ isOpenConfirmDeletePayment: isOpen, paymentId })),

  transactionId: undefined,
  isOpenConfirmDeleteExpenseTransaction: false,
  setIsOpenConfirmDeleteExpenseTransaction: (isOpen: boolean, transactionId?: number) => {
    set(() => ({ isOpenConfirmDeleteExpenseTransaction: isOpen, transactionId }));
  },

  amount: undefined,
  state: undefined,
  isOpenViewChargeDebtPayment: false,
  setIsOpenViewChargeDebtPayment: (
    isOpen: boolean,
    paymentId?: number,
    amount?: number,
    state?: string
  ) => set(() => ({ isOpenViewChargeDebtPayment: isOpen, paymentId, amount, state })),

  isOpenAddExpense: false,
  setIsOpenAddExpense: (isOpen: boolean) => set(() => ({ isOpenAddExpense: isOpen })),

  isOpenAddExpensesTransactionV2: false,
  setIsOpenAddExpensesTransactionV2: (isOpen: boolean) =>
    set(() => ({ isOpenAddExpensesTransactionV2: isOpen })),

  isOpenResolveDocument: false,
  document: undefined,
  setIsOpenResolveDocument: (isOpen: boolean, document?: any) =>
    set(() => ({ isOpenResolveDocument: isOpen, document })),

  isOpenSetBreakfast: false,
  setIsOpenSetBreakfast: (isOpen: boolean) => set(() => ({ isOpenSetBreakfast: isOpen })),

  isOpenUndoCheckIn: false,
  setIsOpenUndoCheckIn: (isOpen: boolean) => set(() => ({ isOpenUndoCheckIn: isOpen })),

  isOpenUndoCheckOut: false,
  setIsOpenUndoCheckOut: (isOpen: boolean) => set(() => ({ isOpenUndoCheckOut: isOpen })),

  isOpenSignature: false,
  propsSignatureModal: undefined,
  setIsOpenSignature: (isOpen: boolean, propsSignatureModal: PropsModal | undefined) =>
    set(() => ({
      isOpenSignature: isOpen,
      propsSignatureModal
    })),

  isOpenChangePassword: false,
  setIsOpenChangePassword: (isOpenChangePassword: boolean) => set(() => ({ isOpenChangePassword })),

  isOpenPayment: false,
  setIsOpenPayment: (isOpenPayment: boolean) => set(() => ({ isOpenPayment })),

  isOpenCreateTravelAgency: false,
  setIsOpenCreateTravelAgency: (isOpenCreateTravelAgency: boolean) =>
    set(() => ({ isOpenCreateTravelAgency })),

  isOpenUpdateAndDeleteTravelAgency: false,
  setIsOpenUpdateAndDeleteTravelAgency: (isOpenUpdateAndDeleteTravelAgency: boolean) =>
    set(() => ({ isOpenUpdateAndDeleteTravelAgency })),

  isOpenGroupRoomingList: false,
  groupRoomingData: undefined,
  setIsOpenGroupRoomingList: (isOpenGroupRoomingList: boolean, groupRoomingData?: any) =>
    set(() => ({ isOpenGroupRoomingList, groupRoomingData })),

  isOpenSubGroupDetail: false,
  subGroupDetail: undefined,
  setIsOpenSubGroupDetail: (isOpenSubGroupDetail: boolean) => set(() => ({ isOpenSubGroupDetail })),
  setSubGroupDetail: (subGroupDetail: SubGroup | undefined) => set(() => ({ subGroupDetail })),

  isOpenChangeDateGroupBooking: false,
  setIsOpenChangeDateGroupBooking: (isOpenChangeDateGroupBooking: boolean) =>
    set(() => ({ isOpenChangeDateGroupBooking })),

  isOpenCheckRoomAvailability: false,
  setIsOpenCheckRoomAvailability: isOpenCheckRoomAvailability =>
    set(() => ({ isOpenCheckRoomAvailability })),

  isOpenFocDetails: false,
  setIsOpenFocDetails: isOpenFocDetails => set(() => ({ isOpenFocDetails })),

  isPDFOpen: false,
  setIsPDFOpen: isPDFOpen => set(() => ({ isPDFOpen })),

  // Post room charge modal ========================================
  postRoomCharge: {
    bookingLineId: 0,
    isOpenPostRoomCharge: false
  },
  openPostRoomCharge: bookingLineId =>
    set(() => ({ postRoomCharge: { bookingLineId, isOpenPostRoomCharge: true } })),
  closePostRoomCharge: () =>
    set(() => ({ postRoomCharge: { bookingLineId: undefined, isOpenPostRoomCharge: false } })),

  postDummyRoomCharge: {
    dataGroup: undefined,
    isOpenPostDummyRoomCharge: false
  },
  openPostDummyRoomCharge: (dataGroup: ResponseGroupBookingDetail | undefined) =>
    set(() => ({ postDummyRoomCharge: { dataGroup, isOpenPostDummyRoomCharge: true } })),
  closePostDummyRoomCharge: () =>
    set(() => ({
      postDummyRoomCharge: { dataGroup: undefined, isOpenPostDummyRoomCharge: false }
    })),

  moveTransaction: {
    transaction_ids: [],
    bookingLineId: 0,
    code: '',
    guest_id: 0,
    isOpenMoveTransaction: false
  },
  openMoveTransaction: (
    transaction_ids: number[],
    bookingLineId: number,
    code: string,
    guest_id: number
  ) =>
    set(() => ({
      moveTransaction: {
        transaction_ids,
        bookingLineId,
        code,
        guest_id,
        isOpenMoveTransaction: true
      }
    })),
  closeMoveTransaction: () =>
    set(() => ({
      moveTransaction: {
        transaction_ids: [],
        bookingLineId: undefined,
        code: '',
        guest_id: undefined,
        isOpenMoveTransaction: false
      }
    })),

  moveSaleOrder: {
    bookingLineId: 0,
    guest_id: 0,
    guest_order_id: 0,
    isOpenMoveSaleOrder: false
  },
  openMoveSaleOrder: (bookingLineId: number, guest_id: number, guest_order_id: number) =>
    set(() => ({
      moveSaleOrder: {
        bookingLineId,
        guest_id,
        guest_order_id,
        isOpenMoveSaleOrder: true
      }
    })),
  closeMoveSaleOrder: () =>
    set(() => ({
      moveSaleOrder: {
        bookingLineId: undefined,
        guest_id: undefined,
        guest_order_id: 0,
        isOpenMoveSaleOrder: false
      }
    })),

  isOpenTransactionSearchBooking: false,
  setIsOpenTransactionSearchBooking: isOpenTransactionSearchBooking =>
    set(() => ({ isOpenTransactionSearchBooking })),

  postPaymentTransaction: {
    folio_balance_code: '',
    booking_line_id: undefined,
    guest_id: undefined,
    orderId: undefined,
    isOpenPostPaymentTransaction: false
  },
  openPostPaymentTransaction: (
    folio_balance_code: string,
    booking_line_id: number,
    guest_id: number,
    orderId: number
  ) =>
    set(() => ({
      postPaymentTransaction: {
        folio_balance_code,
        booking_line_id,
        guest_id,
        orderId,
        isOpenPostPaymentTransaction: true
      }
    })),
  closePostPaymentTransaction: () =>
    set(() => ({
      postPaymentTransaction: {
        folio_balance_code: undefined,
        booking_line_id: undefined,
        guest_id: undefined,
        orderId: undefined,
        isOpenPostPaymentTransaction: false
      }
    })),

  postRefundTransaction: {
    folio_balance_code: '',
    booking_line_id: undefined,
    guest_id: undefined,
    orderId: undefined,
    isOpenPostRefundTransaction: false
  },
  openPostRefundTransaction: (
    folio_balance_code: string,
    booking_line_id: number,
    guest_id: number,
    orderId: number
  ) =>
    set(() => ({
      postRefundTransaction: {
        folio_balance_code,
        booking_line_id,
        guest_id,
        orderId,
        isOpenPostRefundTransaction: true
      }
    })),
  closePostRefundTransaction: () =>
    set(() => ({
      postRefundTransaction: {
        folio_balance_code: undefined,
        booking_line_id: undefined,
        guest_id: undefined,
        orderId: undefined,
        isOpenPostRefundTransaction: false
      }
    })),

  cancelTransactions: {
    transaction_ids: [],
    isOpenCancelTransactions: false
  },
  openCancelTransactions: (transaction_ids: number[]) =>
    set(() => ({
      cancelTransactions: {
        transaction_ids,
        isOpenCancelTransactions: true
      }
    })),
  closeCancelTransaction: () =>
    set(() => ({
      cancelTransactions: {
        transaction_ids: [],
        isOpenCancelTransactions: false
      }
    })),

  undoCancelledTransactions: {
    cancelled_transaction_ids: [],
    isOpenUndoCancelledTransactions: false
  },
  openUndoCancelledTransactions: (cancelled_transaction_ids: number[]) =>
    set(() => ({
      undoCancelledTransactions: {
        cancelled_transaction_ids,
        isOpenUndoCancelledTransactions: true
      }
    })),
  closeUndoCancelledTransactions: () =>
    set(() => ({
      undoCancelledTransactions: {
        cancelled_transaction_ids: [],
        isOpenUndoCancelledTransactions: false
      }
    })),

  createInvoiceTransaction: {
    orders: [],
    guest_id: undefined,
    code: '',
    booking_line_id: 0,
    isOpenCreateInvoice: false
  },
  openCreateInvoiceTransaction: (
    orders: any[],
    guest_id: number | undefined,
    code: string,
    booking_line_id: number,
    isOpenCreateInvoice: boolean
  ) =>
    set(() => ({
      createInvoiceTransaction: {
        orders,
        guest_id,
        code,
        booking_line_id,
        isOpenCreateInvoice
      }
    })),
  closeCreateInvoiceTransaction: () =>
    set(() => ({
      createInvoiceTransaction: {
        orders: [],
        guest_id: undefined,
        code: '',
        booking_line_id: 0,
        isOpenCreateInvoice: false
      }
    })),

  isOpenAddDiscount: false,
  setIsOpenAddDiscount: isOpenAddDiscount => set(() => ({ isOpenAddDiscount })),

  bookingLineOrderId: undefined,
  isOpenTransactionHistory: false,
  setIsOpenTransactionHistory: (
    isOpenTransactionHistory: boolean,
    bookingLineOrderId?: number | undefined
  ) => set(() => ({ isOpenTransactionHistory, bookingLineOrderId }))
}));

export default useModal;
