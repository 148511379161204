import { CustomMenuItem } from 'helper/type/action.type';

import React from 'react';
import { Button, ButtonProps } from 'antd';
import { usePermissions } from 'AuthorizePermission';

/**
 * Filters booking actions based on permissions and action list.
 * @param {any[]} actionList - The list of booking actions to filter.
 * @param {Record<string, boolean>} permissions - The permissions object.
 * @param {(permissionCode: string) => boolean} hasPermission - Function to check if a permission is available.
 * @returns {CustomMenuItem[]} - The filtered list of booking actions.
 */

export const getActionsPermission = (
  actionList: CustomMenuItem[],
  permissions: Record<string, boolean>,
  hasPermission: (permissionCode: string) => boolean
): any[] => {
  if (!hasPermission) {
    return actionList;
  }
  return actionList.filter(action => {
    return action.permission ? hasPermission(action.permission) : true;
  });
};

// Component for the Button

interface IBButtonProps extends ButtonProps {
  permission: string;
}
const IBButton: React.FC<IBButtonProps> = ({ permission, children, ...rest }) => {
  const { hasPermission } = usePermissions();

  if (!hasPermission(permission)) {
    return null;
  }

  return <Button {...rest}>{children}</Button>;
};
export default IBButton;
