import { CustomMenuItem, PermissionActionTypes } from 'helper/type/action.type';
import { ReactComponent as IconExclamation } from 'assets/images/exclamation-circle.svg';
import { ReactComponent as IconBed } from 'assets/images/bed.svg';
import { ReactComponent as IconAssign } from 'assets/images/assign.svg';
import { ReactComponent as IconCancelAssign } from 'assets/images/cancel-assign.svg';
import { ReactComponent as IconPlusCircle } from 'assets/images/plus-circle.svg';
import { ReactComponent as IconCalendar } from 'assets/images/calendar.svg';
import { ReactComponent as IconSwap } from 'assets/images/swap.svg';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { usePermissions } from 'AuthorizePermission';
import { ACTION_BOOKING } from 'components/booking-list/action-booking';
import { useFilterActions } from 'helper/common/permission.action.helper';

const ACTION_BOOKING_LIST: CustomMenuItem[] = [
  {
    key: ACTION_BOOKING.DETAIL,
    label: (
      <div className="action-item">
        <IconExclamation />
        <span>Chi tiết</span>
      </div>
    ),
    permission: PermissionActionTypes.BOOKING_LIST_DETAIL
  },
  {
    label: (
      <div className="action-item">
        <IconBed />
        <span>Nhận phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.GET_ROOM,
    permission: PermissionActionTypes.CHECK_IN
  },
  {
    label: (
      <div className="action-item">
        <IconAssign />
        <span>Gán phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.ASSIGN_ROOM,
    permission: PermissionActionTypes.ASSIGN_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconCancelAssign />
        <span>Bỏ gán phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CANCEL_ASSIGN_ROOM,
    permission: PermissionActionTypes.UNASSIGN_ROOM
  },
  {
    type: 'divider',
    key: ACTION_BOOKING.DETAIL_DIVIDER
  },
  {
    label: (
      <div className="action-item">
        <IconPlusCircle />
        <span>Thêm dịch vụ</span>
      </div>
    ),
    key: ACTION_BOOKING.ADD_SERVICE
  },
  {
    label: (
      <div className="action-item">
        <IconCalendar />
        <span>Đổi ngày ở</span>
      </div>
    ),
    key: ACTION_BOOKING.CHANGE_DATE,
    permission: PermissionActionTypes.CHANGE_DATE
  },
  {
    label: (
      <div className="action-item">
        <IconSwap />
        <span>Chuyển phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CHANGE_ROOM,
    permission: PermissionActionTypes.CHANGE_ROOM
  },
  {
    type: 'divider',
    key: ACTION_BOOKING.CANCEL_BOOKING_DIVIDER
  },
  {
    label: (
      <div className="action-item">
        <IconClose />
        <span>Huỷ đặt phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CANCEL_BOOKING
  }
];

export const useFilteredActionBookingList = (): CustomMenuItem[] => {
  const { hasPermission, permissions } = usePermissions();
  return useFilterActions(ACTION_BOOKING_LIST, permissions, hasPermission);
};
