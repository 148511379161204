import _ from 'lodash';
import React, { useEffect } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, matchPath } from 'react-router-dom';
import {
  Checkbox,
  Col,
  Form,
  FormProps,
  Input,
  InputNumber,
  Modal,
  notification,
  Row,
  Select
} from 'antd';

import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { CURRENCY, CURRENCY_OPTIONS, PAYMENT_METHOD, PAYMENT_METHOD_OPTIONS } from 'constants/form';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { formatCurrency } from 'utils';
import { actionCancelBooking, batchActionBookings } from 'services/api/module/booking.api';
import {
  BATCH_ACTION_TYPE,
  ParamsBatchAction,
  ParamsCancelBooking
} from 'services/api/type/booking.type';

import QUERY_KEYS from 'services/api/queryKeys';
import PAGES from 'routes/constants';
import { formatInputNumber } from 'utils/currency';
import { refreshRoomMap } from 'hooks';
import { useEnableV2 } from 'hooks/useGetCurrentBranch';
import { queryClient } from 'index';

function CancelRoomModal() {
  const [form] = Form.useForm();
  const location = useLocation();
  const isChargeFeeCancelForm = Form.useWatch('isChargeFeeCancel', form);
  const { enable: enableV2 } = useEnableV2();

  const { isOpenCancelRoom, setIsOpenCancelRoom } = useModalStore();
  const {
    bookingLineDetail,
    checkedListBookingLineId,
    pageBookingList,
    bookingLineId,
    isActionOnGroup,
    setIsActionOnGroup
  } = useBookingStore();

  const { mutateAsync: mutateCancel, isPending: isPendingCancel } = useMutation({
    mutationFn: ({
      booking_line_id,
      params
    }: {
      booking_line_id: number;
      params: ParamsCancelBooking;
    }) => actionCancelBooking(booking_line_id, params)
  });

  const { mutateAsync: mutateBatchActionBooking } = useMutation({
    mutationFn: (params: ParamsBatchAction) => batchActionBookings(params)
  });

  useEffect(() => {
    if (!isOpenCancelRoom) {
      form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenCancelRoom]);

  const handleCloseCancelRoom = () => {
    setIsOpenCancelRoom(false);
    setIsActionOnGroup(false);
  };

  const handleCancelAssignRoom = async () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: ParamsCancelBooking) => {
    const bookingLineIdParams = bookingLineDetail?.booking_line_id || bookingLineId;
    if (bookingLineIdParams || !_.isEmpty(checkedListBookingLineId)) {
      // Action group
      try {
        if (isActionOnGroup && !_.isEmpty(checkedListBookingLineId)) {
          await mutateBatchActionBooking({
            action: BATCH_ACTION_TYPE.CANCEL,
            booking_line_ids: checkedListBookingLineId,
            reason: values.note
          });
        } else if (bookingLineIdParams) {
          await mutateCancel({
            booking_line_id: bookingLineIdParams,
            params: {
              payment_method: values.payment_method,
              fee: values.fee,
              is_no_show: values.is_no_show,
              note: values.note
              // is_send_email: values.is_send_email,
            }
          });
        }
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }

      if (
        matchPath(PAGES.bookingList, location.pathname) ||
        matchPath(PAGES.receptionistGuestCheckIn, location.pathname) ||
        matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname)
      ) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST, pageBookingList]
        });
      }

      if (matchPath(PAGES.nightAudit, location.pathname)) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
        });
      }

      if (matchPath(PAGES.cleaningCalendar, location.pathname)) {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
        });
      }

      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.KEY_ROOM,
          QUERY_KEYS.GET_BOOKING_DETAIL,
          bookingLineDetail?.booking_line_id
        ]
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, bookingLineDetail?.booking_line_id]
      });

      refreshRoomMap(location);

      handleCloseCancelRoom();
    }
  };

  return (
    <Modal
      title="Xác nhận hủy đặt phòng/ Noshow"
      centered
      width={410}
      open={isOpenCancelRoom}
      onCancel={handleCloseCancelRoom}
      closeIcon={<IconClose />}
      okText="Xác nhận"
      cancelText="Đóng"
      onOk={handleCancelAssignRoom}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-cancel-room"
      destroyOnClose
      confirmLoading={isPendingCancel}
    >
      <Form
        form={form}
        name="cancel-room-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          note: '',
          fee: 0,
          is_send_email: false,
          is_no_show: false,
          isChargeFeeCancel: false,
          payment_method: PAYMENT_METHOD.CASH,
          currency: CURRENCY.VND
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="note"
          rules={[
            {
              required: true,
              message: 'Hãy nhập lý do'
            }
          ]}
        >
          <Input.TextArea placeholder="Lý do (bắt buộc)" rows={6}></Input.TextArea>
        </Form.Item>

        {_.isEmpty(checkedListBookingLineId) && (
          <>
            <Form.Item name="is_no_show" valuePropName="checked" style={{ marginBottom: '8px' }}>
              <Checkbox>NoShow</Checkbox>
            </Form.Item>
            <Form.Item name="is_send_email" valuePropName="checked" style={{ marginBottom: '8px' }}>
              <Checkbox>Gửi email cho khách</Checkbox>
            </Form.Item>
            {enableV2 ? (
              <span style={{ color: 'red', paddingBottom: '10px' }} className="note text-light">
                Note: Thực hiện ghi nhận phí huỷ/ Noshow ở thu ngân
              </span>
            ) : (
              <Form.Item name="isChargeFeeCancel" valuePropName="checked">
                <Checkbox>Thu phí hủy phòng</Checkbox>
              </Form.Item>
            )}
          </>
        )}

        {isChargeFeeCancelForm && (
          <>
            <Row gutter={[8, 8]}>
              <Col span={12}>
                <Form.Item name="payment_method">
                  <Select options={PAYMENT_METHOD_OPTIONS} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="currency">
                  <Select options={CURRENCY_OPTIONS} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="fee" className="m-0" style={{ marginBottom: '8px' }}>
              <InputNumber
                formatter={value => formatInputNumber(value)}
                min={0}
                className="w-full"
              />
            </Form.Item>
            <span className="text-grey">
              * Đã thanh toán : {formatCurrency(bookingLineDetail?.paid_amount || 0)}
            </span>
          </>
        )}
      </Form>
    </Modal>
  );
}

export default CancelRoomModal;
