import React, { useEffect, useState } from 'react';
import {
  Modal,
  Form,
  Row,
  Col,
  Input,
  notification,
  Button,
  QRCode,
  Card,
  Checkbox,
  Image,
  InputNumber,
  Popconfirm,
  Upload,
  Select
} from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { getAddDiscountPath, getRedirectUrl } from 'routes/constants';
import useModal from 'stores/useModal';
import { formatInputNumber } from 'utils/currency';
import {
  BookingLine,
  ParamsAddDiscount,
  ParamsAddDiscountImage
} from 'services/api/type/booking.type';
import API from 'services/api';
import { useMutation } from '@tanstack/react-query';
import { useGetBookingDiscount } from 'hooks/useGetBookingDiscount';
import { getBase64 } from 'utils';
import { DISCOUNT_OPTIONS } from 'constants/form';

interface Props {
  bookingLineDetail: BookingLine;
}

function AddDiscount({ bookingLineDetail }: Props) {
  const [form] = Form.useForm();

  const discount_option = Form.useWatch('discount_option', form);

  const { isOpenAddDiscount, setIsOpenAddDiscount } = useModal();
  const [isShowPreview, setIsShowPreview] = useState<boolean>(false);

  const [discountSymbol, setDiscountSymbol] = useState<string>('');
  const [discountPlaceholder, setDiscountPlaceholder] = useState<string>('');

  const { data: BookingDiscount } = useGetBookingDiscount(bookingLineDetail.booking_line_id);

  const [freeRoomCharge, setFreeRoomCharge] = useState<boolean>(BookingDiscount?.is_foc || false);
  const [imageUrl, setImageUrl] = useState<string>(BookingDiscount?.discount_image_url || '');

  const totalPrice = bookingLineDetail.price * bookingLineDetail.booking_days;

  const { mutateAsync: mutateDiscount, isPending } = useMutation({
    mutationFn: (values: ParamsAddDiscount) =>
      API.booking.addDiscount(bookingLineDetail.booking_line_id, values)
  });

  const { mutateAsync: mutateDiscountImage } = useMutation({
    mutationFn: (values: ParamsAddDiscountImage) =>
      API.booking.addDiscountImage(bookingLineDetail.booking_line_id, values)
  });

  const handleCancel = () => {
    setIsOpenAddDiscount(false);
    form.resetFields();
  };

  const handleImageDelete = async () => {
    try {
      await mutateDiscountImage({
        discount_image: ''
      });
      setImageUrl('');
      notification.success({
        message: 'Xóa ảnh thành công'
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PAYMENTS, bookingLineDetail?.booking_line_id]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.GET_BOOKNIG_DISCOUNT,
          QUERY_KEYS.GET_BOOKING_DETAIL,
          bookingLineDetail.booking_line_id
        ]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  useEffect(() => {
    if (isOpenAddDiscount && BookingDiscount) {
      if (BookingDiscount.discount_amount) {
        form.setFieldsValue({
          discount_option: 'discount_amount',
          discount_amount: BookingDiscount.discount_amount || 0
        });
      } else if (BookingDiscount.discount_percent) {
        form.setFieldsValue({
          discount_option: 'discount_percent',
          discount_percent: BookingDiscount.discount_percent || 0
        });
      }
      form.setFieldsValue({
        discount_reason: BookingDiscount.discount_reason || ''
      });
      setImageUrl(BookingDiscount?.discount_image_url || '');
      setFreeRoomCharge(BookingDiscount?.is_foc || false);
    } else {
      form.resetFields();
    }
    // eslint-disable-next-line
  }, [isOpenAddDiscount, bookingLineDetail, BookingDiscount]);

  const onFinish = async (values: any) => {
    try {
      await mutateDiscount({
        discount_percent: values.discount_percent,
        discount_amount: values.discount_amount,
        discount_reason: values.discount_reason,
        is_foc: values.is_foc
      });
      notification.success({
        message: 'Lưu chiết khấu thành công'
      });
      setIsOpenAddDiscount(false);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PAYMENTS, bookingLineDetail?.booking_line_id]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.GET_BOOKNIG_DISCOUNT,
          QUERY_KEYS.GET_BOOKING_DETAIL,
          bookingLineDetail.booking_line_id
        ]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleUpload = async (file: any) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      notification.error({
        message: 'Chỉ chấp nhận file hình ảnh!'
      });
      return false;
    }
    const blob = URL.createObjectURL(file);
    if (blob) {
      const fileBase64 = await getBase64(file);

      try {
        await mutateDiscountImage({
          discount_image: String(fileBase64)
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL]
        });
        await queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.GET_BOOKNIG_DISCOUNT,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail.booking_line_id
          ]
        });
      } catch (err: any) {
        notification.error({
          message: err.message || 'Có lỗi xảy ra khi tải lên'
        });
      }
    }
    return false;
  };

  useEffect(() => {
    if (isOpenAddDiscount) {
      if (discount_option === 'discount_percent') {
        setDiscountSymbol('%');
        setDiscountPlaceholder('Nhập % giảm giá');
      } else if (discount_option === 'discount_amount') {
        setDiscountSymbol('VND');
        setDiscountPlaceholder('Nhập VND giảm giá');
      } else if (discount_option === 'discount_code') {
        setDiscountSymbol('CODE');
      } else if (discount_option === 'discount_qr') {
        setDiscountSymbol('QR');
      }
    } else {
      setDiscountSymbol('');
    }
  }, [discount_option, isOpenAddDiscount]);

  return (
    <Modal
      title="Chiết khấu (Lưu ý: chiết khấu chỉ áp dụng cho tiền phòng)"
      centered
      width={850}
      open={isOpenAddDiscount}
      onCancel={handleCancel}
      destroyOnClose
      confirmLoading={isPending}
      footer={[
        <Button key="submit" type="primary" onClick={() => form.submit()}>
          Lưu
        </Button>
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={[8, 8]}>
          <Card style={{ width: '70%' }}>
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <p className="ant-upload-hint m-0">Hình thức chiết khấu</p>
                <Form.Item name="discount_option">
                  <Select
                    options={DISCOUNT_OPTIONS}
                    labelRender={option => <span>{option.label}</span>}
                    placeholder="Chọn hình thức chiết khấu"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <p className="ant-upload-hint m-0">Giảm giá {discountSymbol}</p>
                <Form.Item name={discount_option}>
                  <InputNumber
                    addonBefore={discountSymbol ? discountSymbol : '...'}
                    style={{ width: '100%' }}
                    min={0}
                    max={totalPrice}
                    placeholder={discountPlaceholder ? discountPlaceholder : '...'}
                    disabled={BookingDiscount?.is_foc}
                    formatter={value => formatInputNumber(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <p className="ant-upload-hint m-0">Lý do chiết khấu</p>
                <Form.Item name="discount_reason">
                  <Input
                    placeholder="Lý do chiết khấu"
                    style={{ width: '100%', fontSize: '16px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
          <Col
            span={7}
            style={{
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Form.Item name="is_foc" valuePropName="checked">
              <div style={{ marginTop: '20px' }}>
                <Checkbox
                  checked={freeRoomCharge}
                  onChange={e => {
                    setFreeRoomCharge(e.target.checked);
                    form.setFieldsValue({ is_foc: e.target.checked });
                  }}
                />{' '}
                <span>Miễn phí tiền phòng</span>
              </div>
            </Form.Item>

            {imageUrl ? (
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <Image
                  style={{ display: 'block' }}
                  preview={{
                    visible: isShowPreview,
                    onVisibleChange: visible => setIsShowPreview(visible)
                  }}
                  src={imageUrl}
                />
                <Popconfirm
                  title="Bạn có chắc chắn muốn xóa ảnh không?"
                  onConfirm={handleImageDelete}
                  okText="Có"
                  cancelText="Không"
                >
                  <Button
                    size="small"
                    type="link"
                    style={{
                      position: 'absolute',
                      top: 10,
                      right: 10,
                      background: 'rgba(255, 255, 255, 0.7)'
                    }}
                  >
                    Xóa
                  </Button>
                </Popconfirm>
              </div>
            ) : (
              <>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <QRCode
                      value={getRedirectUrl(getAddDiscountPath(bookingLineDetail.booking_line_id))}
                    />
                    <p className="ant-upload-hint m-0" style={{ textAlign: 'center' }}>
                      Hỗ trợ ảnh png, jpg, jpeg, bmp và dưới 2MB. Tối đa 5 ảnh
                    </p>
                  </div>
                  <Upload
                    customRequest={({ file, onSuccess }) => {
                      handleUpload(file);
                      onSuccess && onSuccess(null);
                    }}
                    showUploadList={false}
                  >
                    <Button
                      icon={<CloudUploadOutlined />}
                      type="primary"
                      style={{ position: 'absolute', top: '67px', right: '0%' }}
                    />
                  </Upload>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default AddDiscount;
