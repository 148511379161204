import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Modal,
  Form,
  FormProps,
  Row,
  Col,
  Select,
  Checkbox,
  Spin,
  notification,
  Input,
  InputNumber,
  Button
} from 'antd';
import { IdcardOutlined } from '@ant-design/icons';

import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ReactComponent as IconCancelCalendar } from 'assets/images/cancel-calendar.svg';

import { RoomType } from 'services/api/type/room.type';
import { formatCurrency, toUtcTime } from 'utils';
import { exchangeRoom } from 'services/api/module/room.api';
import { FILTER_STATUS_ROOM_MAP_VALUE } from 'constants/common';
import QUERY_KEYS from 'services/api/queryKeys';
import { MESSAGE_CODE } from 'constants/validate';
import { formatInputNumber } from 'utils/currency';
import { PermissionActionTypes } from 'helper/type/action.type';
import IBButton from 'helper/permission.action';

function ChangeRoomModal() {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { bookingLineId } = useBookingStore();
  const { isOpenChangeRoom, setIsOpenChangeRoom } = useModalStore();

  const roomTypeId: number = Form.useWatch('room_type_id', form);
  const isChangeRate: boolean = Form.useWatch('is_change_rate', form);
  const priceListId: number = Form.useWatch('pricelist_id', form);

  const [availableRooms, setAvailableRooms] = useState<any[]>([]);
  const [pricingList, setPricingList] = useState<any[]>([]);

  const [rangeDate, setRangeDate] = useState(['', '']);
  const { data: availRoomTypes, isFetching } = useGetAvailRoomType(
    toUtcTime(rangeDate[0], 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(rangeDate[1], 'YYYY-MM-DD HH:mm:ss')
  );
  const { data: bookingLineDetail } = useGetBookingDetail(bookingLineId);

  const { mutateAsync: mutateExchangeRoom, isPending: isPendingAssign } = useMutation({
    mutationFn: (params: any = {}) => exchangeRoom(params)
  });

  useEffect(() => {
    if (!_.isEmpty(bookingLineDetail)) {
      setRangeDate([bookingLineDetail?.check_in || '', bookingLineDetail?.check_out || '']);
      if (isOpenChangeRoom) {
        form.setFieldValue('room_type_id', bookingLineDetail?.room_type_id);
      }
    }
    if (!isOpenChangeRoom) {
      form.resetFields(['note']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingLineDetail, isOpenChangeRoom]);

  // useEffect(() => {
  //   if (!_.isEmpty(availRoomTypes)) {
  //     formAssign.setFieldValue(
  //       "room_type_id",
  //       bookingLineDetail?.room_type_id || availRoomTypes[0].room_type_id
  //     );
  //   }
  // }, [availRoomTypes, bookingLineDetail?.room_type_id, formAssign]);

  useEffect(() => {
    if (roomTypeId) {
      const roomType: RoomType | undefined = _.find(availRoomTypes, {
        room_type_id: roomTypeId
      });
      if (roomType) {
        setAvailableRooms(
          roomType.rooms
            .filter(item => item.room_status !== FILTER_STATUS_ROOM_MAP_VALUE.BOOKED)
            .map(item => ({
              label: item.room_name,
              value: item.room_id
            }))
        );

        const pricingList = [
          {
            label: `Giá mặc định - ${formatCurrency(roomType.price)}`,
            value: ''
          },
          ...(roomType.pricing || []).map(item => ({
            label: `${item.pricelist_name} - ${formatCurrency(item.price)}`,
            value: item.pricelist_id
          }))
        ];
        setPricingList(pricingList);

        form.setFieldsValue({
          room_id: null,
          pricelist_id: ''
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, roomTypeId]);

  useEffect(() => {
    if (isChangeRate && !priceListId) {
      form.setFieldValue('pricelist_id', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangeRate, priceListId]);

  const handleCloseChangeRoom = () => {
    setIsOpenChangeRoom(false);
    form.resetFields();
    form.setFieldValue('room_type_id', bookingLineDetail?.room_type_id);
  };

  const handleChangeRoom = async () => {
    form.submit();
  };

  const onFinishAssign: FormProps['onFinish'] = async (values: any) => {
    const { is_change_rate, pricelist_id, room_id, note, fee } = values;
    if (bookingLineDetail) {
      try {
        await mutateExchangeRoom({
          booking_line_id: bookingLineDetail.booking_line_id,
          room_id,
          is_change_rate,
          note,
          exchange_fee: fee,
          pricelist_id: pricelist_id || undefined
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM]
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
        });
        setIsOpenChangeRoom(false);
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const roomTypeOptions = useMemo(() => {
    return (availRoomTypes || []).map(item => ({
      label: item.room_type_name,
      value: item.room_type_id
    }));
  }, [availRoomTypes]);

  return (
    <Modal
      title="Chuyển phòng"
      centered
      width={410}
      open={isOpenChangeRoom}
      onCancel={handleCloseChangeRoom}
      closeIcon={<IconClose />}
      okText={null}
      cancelText="Đóng"
      className="modal-assign-room"
      confirmLoading={isPendingAssign}
      destroyOnClose
      onOk={handleChangeRoom}
      footer={[
        <Button key="cancel" onClick={handleCloseChangeRoom}>
          Đóng
        </Button>,
        <IBButton
          key="ok"
          permission={PermissionActionTypes.CHANGE_ROOM}
          onClick={handleChangeRoom}
          className="ant-btn-secondary"
        >
          Lưu
        </IBButton>
      ]}
    >
      {isFetching ? (
        <Spin className="flex items-center justify-center" style={{ height: '200px' }} />
      ) : (
        <Form
          form={form}
          name="change-room-form"
          layout="vertical"
          style={{ width: '100%' }}
          initialValues={{
            room_type_id: bookingLineDetail?.room_type_id,
            room_id: bookingLineDetail?.room_id,
            is_change_rate: false,
            pricelist_id: null,
            note: ''
          }}
          onFinish={onFinishAssign}
          autoComplete="off"
        >
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <div className="col-type-room">
                <h4 className="title m-0">
                  <IconCancelCalendar />
                  {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
                </h4>
                <Form.Item label="Loại phòng" name="room_type_id">
                  <Select options={roomTypeOptions} />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-room">
                <div className="title">
                  <IdcardOutlined />
                  <p className="m-0 truncate" title={bookingLineDetail?.partner_name || 'Guest'}>
                    {bookingLineDetail?.partner_name || 'Guest'}
                  </p>
                </div>
                <Form.Item label="Chọn phòng" name="room_id">
                  <Select options={availableRooms} placeholder="Chọn phòng" />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Form.Item
            label="Lý do"
            name="note"
            style={{ marginBottom: '12px' }}
            rules={[
              {
                required: true,
                message: MESSAGE_CODE.REQUIRED_REASON_FIX_ROOM
              }
            ]}
          >
            <Input.TextArea rows={4} />
          </Form.Item>

          <Form.Item name="is_change_rate" valuePropName="checked" style={{ marginBottom: '12px' }}>
            <Checkbox>Đổi loại giá</Checkbox>
          </Form.Item>

          {isChangeRate && (
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <Form.Item label="Giá phòng" name="pricelist_id">
                  <Select options={pricingList} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Phí chuyển phòng" name="fee">
                  <InputNumber
                    formatter={value => formatInputNumber(value)}
                    className="w-full"
                    min={0}
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Form>
      )}
    </Modal>
  );
}

export default ChangeRoomModal;
