import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { AllPaymentItem } from '../type/payment.type';
import {
  CashFlowType,
  CreateExpenseType,
  HouseStatusType,
  NationalityStatisticType,
  StayingGuestType,
  ShiftHandoverResponse,
  ReservationForcecastType,
  RoomAvailableType,
  GetFocsType,
  GetConsolidateRevenueType,
  EditCashPayment,
  ParamsGetConsolidateRevenueType,
  EditExpenseCashTransaction,
  CreateExpensesCashTransactionV2,
  ConsolidateSourceDetails,
  GetDetailedAnalysisReport,
  DetailedAnalysisReport
} from '../type/report.type';
import { trimTruthyValues } from 'utils';

export const PAGE_SIZE_SHIFT_HANDOVER = 15;
export const getListShiftHandover = async ({
  date_from,
  date_to,
  limit,
  page
}: {
  date_from: string;
  date_to: string;
  limit?: number;
  page?: number;
}): Promise<ShiftHandoverResponse> => {
  const params = {
    date_from,
    date_to,
    limit,
    page
  };
  return axiosInstance
    .get(paths.getListShiftHandover(), {
      params
    })
    .then(item => item.data);
};

export const PAGE_SIZE_STAYING_GUESTS = 10;
export const getStayingGuests = ({
  start_date,
  end_date,
  status,
  country,
  limit,
  page
}: {
  start_date: string;
  end_date: string;
  status: string;
  country: string;
  limit?: number;
  page?: number;
}): Promise<StayingGuestType[]> => {
  const params = {
    start_date,
    end_date,
    status,
    limit,
    page
  } as any;
  if (country) {
    params.country = country;
  }

  return axiosInstance
    .get(paths.getStayingGuests(), {
      params
    })
    .then(item => item.data);
};

export const PAGE_SIZE_NATIONALITY_STATISTIC = 10;
export const getAllNationality = ({
  start_date,
  end_date,
  status
}: {
  start_date: string;
  end_date: string;
  status: string;
}): Promise<NationalityStatisticType[]> => {
  const params = {
    start_date,
    end_date,
    status
  } as any;
  return axiosInstance
    .get(paths.getAllNationality(), {
      params
    })
    .then(item => item.data);
};

export const PAGE_SIZE_CASH_FLOW = 10;
export const getCashFlow = ({
  start_date,
  end_date,
  booking_id,
  limit,
  page
}: {
  start_date: string;
  end_date: string;
  booking_id?: number;
  limit?: number;
  page?: number;
}): Promise<CashFlowType[]> => {
  return axiosInstance
    .get(paths.getCashFlow(), {
      params: { start_date, end_date, booking_id, limit, page }
    })
    .then(item => item.data);
};

export const PAGE_SIZE_TRANSACTIONS_CASH_FLOW = 10;
export const getTransactionsCashFlow = async ({
  start_date,
  end_date,
  booking_id,
  limit,
  page
}: {
  start_date: string;
  end_date: string;
  booking_id?: number;
  limit?: number;
  page?: number;
}): Promise<CashFlowType[]> => {
  const item = await axiosInstance.get(paths.getTransactionsCashFlow(), {
    params: { start_date, end_date, booking_id, limit, page }
  });
  return item.data;
};

export const createExpense = (params: CreateExpenseType): Promise<CashFlowType[]> => {
  return axiosInstance.post(paths.createExpense(), params).then(item => item.data.data);
};

export const createExpensesCashTransactionV2 = async (
  params: CreateExpensesCashTransactionV2
): Promise<void> => {
  return axiosInstance
    .post(paths.createExpensesCashTransactionV2(), params)
    .then(item => item.data.data);
};

export const getRoomAvailability = async (filterOptions: RoomAvailableType): Promise<any> => {
  return axiosInstance
    .get(paths.getRoomAvailability(), {
      params: filterOptions
    })
    .then(item => item.data.data);
};

export const getRoomSummary = async (date: string): Promise<any> => {
  return axiosInstance
    .get(paths.getRoomSummary(), {
      params: {
        date
      }
    })
    .then(item => item.data.data);
};

export const PAGE_SIZE_TRANSACTION = 10;
export const getTransactions = async (filterOptions: any): Promise<AllPaymentItem[]> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getTransactions(), {
      params: truthyFilterOptions
    })
    .then(res => res.data);
};

export const PAGE_SIZE_TRANSACTIONS_PAYMENT = 10;
export const getTransactionsPayment = async (filterOptions: any): Promise<AllPaymentItem[]> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getTransactionsPayment(), {
      params: truthyFilterOptions
    })
    .then(res => res.data);
};

export const getReservationForecast = async (
  filterOptions: ReservationForcecastType
): Promise<any> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getReservationForecast(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};

export const getHouseStatus = async (date: string): Promise<HouseStatusType> => {
  return axiosInstance
    .get(paths.getHouseStatus(), {
      params: {
        date
      }
    })
    .then(item => item.data.data);
};

export const getRoomsFoc = async (filterOptions: GetFocsType): Promise<any> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.roomsFoc(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};

export const getServicesFoc = async (filterOptions: GetFocsType): Promise<any> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.servicesFoc(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};

export const getConsolidateRevenue = async (
  filterOptions: ParamsGetConsolidateRevenueType
): Promise<GetConsolidateRevenueType> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getConsolidateRevenue(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};

export const getConsolidateSourceRevenue = async (
  filterOptions: ParamsGetConsolidateRevenueType
): Promise<GetConsolidateRevenueType> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getConsolidateSourceRevenue(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};

export const PAGE_SIZE_CONSOLIDATE_SOURCE_DETAILS = 10;
export const getConsolidateSourceRevenueDetails = async (
  filterOptions: ParamsGetConsolidateRevenueType,
  page?: number,
  pageSize?: number
): Promise<ConsolidateSourceDetails> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getConsolidateSourceRevenueDetails(page, pageSize), {
      params: truthyFilterOptions
    })
    .then(item => item.data);
};

export const editCashPayment = async (params: EditCashPayment): Promise<any> => {
  return axiosInstance.put(paths.editCashPayment(), params).then(item => item.data.data);
};

export const editExpenseCashTransaction = async (
  params: EditExpenseCashTransaction
): Promise<any> => {
  return axiosInstance.put(paths.editExpenseCashTransaction(), params).then(item => item.data.data);
};

export const PAGE_SIZE_DETAILED_ANALYSIS_REPORT = 15;
export const detailedAnalysisReport = async (
  params: GetDetailedAnalysisReport
): Promise<DetailedAnalysisReport[]> => {
  return axiosInstance.get(paths.detailedAnalysisReport(), { params }).then(item => item.data);
};
