import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, matchPath } from 'react-router-dom';
import { Modal, notification } from 'antd';

import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { actionUndoCancelledBooking } from 'services/api/module/booking.api';

import QUERY_KEYS from 'services/api/queryKeys';
import PAGES from 'routes/constants';
import { refreshRoomMap } from 'hooks';
import { queryClient } from 'index';

function UndoCancelledRoomModal() {
  const location = useLocation();

  const { isOpenUndoCancelledRoom, setIsOpenUndoCancelledRoom } = useModalStore();
  const { bookingLineDetail, pageBookingList, bookingLineId } = useBookingStore();

  const { mutateAsync: mutateUndoCancelled, isPending: isPendingUndoCancelled } = useMutation({
    mutationFn: ({ booking_line_id }: { booking_line_id: number }) =>
      actionUndoCancelledBooking(booking_line_id)
  });

  const handleCloseCancelRoom = () => {
    setIsOpenUndoCancelledRoom(false);
  };

  const handleActionUndoCancelledBooking = async () => {
    const bookingLineIdParams = bookingLineDetail?.booking_line_id || bookingLineId;
    if (bookingLineIdParams) {
      try {
        await mutateUndoCancelled({
          booking_line_id: bookingLineIdParams
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }

      if (
        matchPath(PAGES.bookingList, location.pathname) ||
        matchPath(PAGES.receptionistGuestCheckIn, location.pathname) ||
        matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname)
      ) {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST, pageBookingList]
        });
      }

      if (matchPath(PAGES.nightAudit, location.pathname)) {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
        });
      }

      if (matchPath(PAGES.cleaningCalendar, location.pathname)) {
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
        });
      }

      await queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.KEY_ROOM,
          QUERY_KEYS.GET_BOOKING_DETAIL,
          bookingLineDetail?.booking_line_id
        ]
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, bookingLineDetail?.booking_line_id]
      });

      refreshRoomMap(location);

      handleCloseCancelRoom();
    }
  };

  return (
    <Modal
      title="Xác nhận hoàn tác hủy phòng"
      centered
      width={410}
      open={isOpenUndoCancelledRoom}
      onCancel={handleCloseCancelRoom}
      closeIcon={<IconClose />}
      okText="Xác nhận"
      cancelText="Đóng"
      onOk={handleActionUndoCancelledBooking}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-cancel-room"
      destroyOnClose
      confirmLoading={isPendingUndoCancelled}
    ></Modal>
  );
}

export default UndoCancelledRoomModal;
