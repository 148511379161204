import { useEffect } from 'react';
import { queryClient } from 'index';
import { useMutation } from '@tanstack/react-query';

import { Modal, Form, notification, Spin, Button } from 'antd';
import { ReactComponent as IconClose } from 'assets/images/close.svg';

import QUERY_KEYS from 'services/api/queryKeys';
import useModal from 'stores/useModal';
import API from 'services/api';

import { useGetCountries } from 'hooks/useGetCountries';
import { TravelAgencyParams, TravelAgencyResponse } from 'services/api/type/travel-agency.type';
import { useGetSources } from 'hooks/useGetSources';
import { useGetCompanies } from 'hooks/useGetCompanies';
import { useGetTravelAgency } from 'hooks/useGetTravelAgencies';

import { renderFormRow } from 'components/modal/travel-agency-component/RowForTravelAgencyAction';
import IBButton from 'helper/permission.action';
import { PermissionActionTypes } from 'helper/type/action.type';

const UpdateAndDeleteTravelAgency = ({
  isOpen,
  onCancel,
  travelAgencyId
}: {
  isOpen: boolean;
  onCancel: () => void;
  travelAgencyId: string;
}) => {
  const { setInfoConfirmModal } = useModal();

  const [form] = Form.useForm();
  const { data: countries } = useGetCountries();
  const { data: sources } = useGetSources();
  const { data: companies } = useGetCompanies();
  const { data: travelAgencyData, isLoading } = useGetTravelAgency(Number(travelAgencyId));

  const { mutateAsync: mutateUpdateTravelAgency } = useMutation({
    mutationFn: (values: TravelAgencyParams) =>
      API.ta.updateTravelAgency(Number(travelAgencyId), values)
  });

  const { mutateAsync: mutateDeleteTravelAgency } = useMutation({
    mutationFn: () => API.ta.deleteTravelAgency(Number(travelAgencyId))
  });

  const onFinish = async (values: TravelAgencyParams) => {
    const filteredValues = { ...values };
    delete filteredValues.is_system;

    try {
      await mutateUpdateTravelAgency(filteredValues);
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES] });
      notification.success({
        message: 'Lưu thành công'
      });
      onCancel();
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleDelete = async () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận xóa công ty du lịch',
      onOk: async () => {
        try {
          await mutateDeleteTravelAgency();
          await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_TRAVEL_AGENCIES] });
          notification.success({
            message: 'Xóa thành công'
          });
          onCancel();
        } catch (err: any) {
          notification.error({
            message: err.error || 'Có lỗi xảy ra'
          });
        } finally {
          setInfoConfirmModal(false);
        }
      }
    });
  };

  const handleDisableButton = () => travelAgencyData?.is_system || false;

  useEffect(() => {
    if (travelAgencyData) {
      const truthyValues = (Object.keys(travelAgencyData) as (keyof TravelAgencyResponse)[])
        .filter(key => travelAgencyData[key] !== null && travelAgencyData[key] !== undefined)
        .reduce((cur, next) => {
          return {
            ...cur,
            [next]: travelAgencyData[next]
          };
        }, {} as Partial<TravelAgencyResponse>);
      form.setFieldsValue(truthyValues);
    }

    if (!isOpen) {
      form.resetFields();
    }
  }, [isOpen, travelAgencyData, form]);

  return (
    <Modal
      title="Cập nhật hoặc xóa công ty du lịch"
      centered
      width={800}
      open={isOpen}
      onCancel={onCancel}
      closeIcon={<IconClose />}
      className="modal-update-or-delete-travel-agency-info"
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={form.submit}
          loading={isLoading}
          disabled={handleDisableButton()}
        >
          Lưu thay đổi
        </Button>,
        <IBButton
          permission={PermissionActionTypes.TA_MANAGEMENT_DELETE}
          key="delete"
          danger
          onClick={handleDelete}
          disabled={handleDisableButton()}
        >
          Xóa
        </IBButton>
      ]}
    >
      <Spin spinning={isLoading}>
        <Form
          form={form}
          name="travel-agency-info-form"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          style={{ width: '100%', padding: '16px' }}
        >
          {renderFormRow({ countries, companies, sources })}
        </Form>
      </Spin>
    </Modal>
  );
};

export default UpdateAndDeleteTravelAgency;
