import { useEffect } from 'react';
import { createHashRouter, useNavigate } from 'react-router-dom';
import { FloatButton } from 'antd';
import { isMobileOnly } from 'react-device-detect';
import { ScanOutlined } from '@ant-design/icons';

import App from 'App';
import AppMobile from 'AppMobile';
import Header from 'components/common/header';

import LoginRegister from 'pages/login-register';
import RoomMap from 'pages/room-map';
import BookingRoom from 'pages/booking-room';
import BookingList from 'pages/booking-list';
import CleaningCalendar from 'pages/cleaning-calendar';
import RoomStatus from 'pages/room-status';
import CleaningDetail from 'pages/cleaning-detail';

import Receptionist from 'pages/receptionist';
import StayingGuests from 'pages/staying-guests';
import NationalityStatistic from 'pages/nationality-statistic';
import CashBooKDetail from 'pages/cash-book-detail';
import NightAudit from 'pages/night-audit';
import CustomerProfile from 'pages/customer-profile';
import RoomAvailability from 'pages/room-availability';
import RoomLock from 'pages/room-lock';
import Transactions from 'pages/transactions';
import ReservationForecast from 'pages/reservation-forecast';
import Breakfasts from 'pages/breakfasts';
import HouseStatus from 'pages/house-status';
import ShiftHandover from 'pages/shift-handover';
import ShiftHandoverReport from 'pages/shift-handover-report';
import NewGroup from 'pages/new-group';
import ListGroup from 'pages/list-group';
import DetailGroup from 'pages/detail-group';

import SubHKMobile from 'pages/mobile/sub-hk';
import HKMobile from 'pages/mobile/hk';

import Auth from 'pages/auth';
import ScanCustomerInfo from 'pages/scan-customer-info';
import WarehouseManagement from 'pages/warehouse-management';
import TravelAgency from 'pages/travel-agency';
import FocsReport from 'pages/focs-report';
import CustomersManagement from 'pages/customers-management';
import ChatPage from 'pages/chat';
import ConsolidateRevenue from 'pages/consolidate-revenue';
import Cashier from 'pages/cashier';
import AddDiscountInfo from 'components/modal/add-discount-image-modal';

import PrivateRoutes from './privateRoutes';
import PAGES from './constants';
import TransactionsV2 from 'pages/transactions-v2';
import CashBookDetailV2 from 'pages/cash-booknig-detail-v2';
import DetailedAnalysisReport from 'pages/detailed-analysis-report';

function Redirect({ to }: { to: string }) {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(to);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}

const FloatButtonWithNavigation = () => {
  const navigate = useNavigate();
  return (
    <FloatButton
      tooltip={<div>Documents</div>}
      icon={<ScanOutlined />}
      onClick={() => navigate(PAGES.scanCustomerInfo)}
    />
  );
};

export const router = createHashRouter([
  {
    path: PAGES.shiftHandover,
    element: (
      <PrivateRoutes>
        <Header />
        <ShiftHandover />
      </PrivateRoutes>
    )
  },
  {
    path: PAGES.chat,
    element: (
      <PrivateRoutes>
        <Header />
        <ChatPage />
      </PrivateRoutes>
    )
  },
  {
    path: PAGES.scanCustomerInfo,
    element: (
      <PrivateRoutes>
        <Header />
        <ScanCustomerInfo />
      </PrivateRoutes>
    )
  },
  {
    path: PAGES.home,
    element: (
      <PrivateRoutes>
        <App />
      </PrivateRoutes>
    ),
    // loader: rootLoader,
    children: [
      {
        path: '',
        element: (
          <>
            {isMobileOnly && <FloatButtonWithNavigation />}
            <BookingRoom />
          </>
        )
        // loader: teamLoader,
      },
      {
        path: PAGES.roomMap,
        element: <RoomMap />
      },
      {
        path: PAGES.bookingList,
        element: <BookingList />
      },
      {
        path: PAGES.cleaningCalendar,
        element: <CleaningCalendar />
      },
      {
        path: PAGES.roomStatus,
        element: <RoomStatus />
      },
      {
        path: PAGES.cleaningDetail,
        element: <CleaningDetail />
      },
      {
        path: PAGES.stayingGuests,
        element: <StayingGuests />
      },
      {
        path: PAGES.cashBookDetail,
        element: <CashBooKDetail />
      },
      {
        path: PAGES.cashBookDetailV2,
        element: <CashBookDetailV2 />
      },
      {
        path: PAGES.nightAudit,
        element: <NightAudit />
      },
      {
        path: PAGES.roomAvailability,
        element: <RoomAvailability />
      },
      {
        path: PAGES.roomLock,
        element: <RoomLock />
      },
      {
        path: PAGES.breakfasts,
        element: <Breakfasts />
      },
      {
        path: PAGES.transactions,
        element: <Transactions />
      },
      {
        path: PAGES.transactionsV2,
        element: <TransactionsV2 />
      },
      {
        path: PAGES.reservationForecast,
        element: <ReservationForecast />
      },
      {
        path: PAGES.houseStatus,
        element: <HouseStatus />
      },
      {
        path: PAGES.nationalityStatistic,
        element: <NationalityStatistic />
      },
      {
        path: PAGES.shiftHandoverList,
        element: <ShiftHandoverReport />
      },
      {
        path: PAGES.focsReport,
        element: <FocsReport />
      },
      {
        path: PAGES.consolidateRevenueReport,
        element: <ConsolidateRevenue />
      },
      {
        path: PAGES.detailedAnalysisReport,
        element: <DetailedAnalysisReport />
      },
      {
        path: PAGES.warehouseManagement,
        element: <WarehouseManagement />
      },
      {
        path: PAGES.travelAgency,
        element: <TravelAgency />
      },
      {
        path: PAGES.customersManagement,
        element: <CustomersManagement />
      },
      {
        path: PAGES.cashier,
        element: <Cashier />
      },

      // Group
      {
        path: PAGES.group,
        element: <NewGroup />
      },
      {
        path: PAGES.listGroup,
        element: <ListGroup />
      },
      {
        path: PAGES.detailGroup,
        element: <DetailGroup />
      },

      // Receptionist
      {
        path: PAGES.receptionist,
        element: <Redirect to={PAGES.receptionistGuestWillCheckIn} />
      },
      {
        path: PAGES.receptionistGuestWillCheckIn,
        element: <Receptionist />
      },
      {
        path: PAGES.receptionistGuestCheckIn,
        element: <Receptionist />
      },
      {
        path: PAGES.receptionistGuestAllot,
        element: <Receptionist />
      },
      {
        path: PAGES.receptionistGuestWillCheckOut,
        element: <Receptionist />
      },
      {
        path: PAGES.receptionistGuestCheckout,
        element: <Receptionist />
      },
      {
        path: PAGES.receptionistUnavailable,
        element: <Receptionist />
      },
      {
        path: PAGES.receptionistActualCheckIn,
        element: <Receptionist />
      }
    ]
  },
  {
    path: PAGES.homeMobile,
    element: (
      <PrivateRoutes>
        <AppMobile />
      </PrivateRoutes>
    ),
    children: [
      {
        path: PAGES.subHK,
        element: <SubHKMobile />
      },
      {
        path: PAGES.hk,
        element: <HKMobile />
      }
    ]
  },
  {
    path: PAGES.addDiscount,
    element: (
      <PrivateRoutes>
        <AddDiscountInfo />
      </PrivateRoutes>
    )
  },
  {
    path: PAGES.customerProfile,
    element: (
      <PrivateRoutes>
        <CustomerProfile />
      </PrivateRoutes>
    )
  },
  {
    path: PAGES.login,
    element: <LoginRegister />
    // loader: rootLoader,
  },
  {
    path: PAGES.auth,
    element: <Auth />
    // loader: rootLoader,
  },
  {
    path: PAGES.register,
    element: <LoginRegister />
    // loader: rootLoader,
  }
]);
