import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './invoice-table-header';
import InvoiceTableRow from './invoice-table-row';
import InvoiceTableFooter from './invoice-table-footer';
import { TransactionPayment } from 'services/api/type/booking.type';

interface Props {
  paymentItems: PaymentItem[];
  remainAmount: number;
  listTax: {
    [key: number]: boolean;
  };
  forGroup?: boolean;
  groupItems?: any[];
  transactionPayment?: TransactionPayment[];
  paidAmount?: number;
  totalPrice?: string;
}

export interface PaymentItem {
  date: string;
  detail: string;
  price: string;
  qty: string;
  serviceFee: string;
  tax: string;
  amount: string;
}

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 4,
    borderWidth: 1,
    borderBottom: 0
  },
  mergeRow: {
    flexDirection: 'row',
    height: 20,
    borderColor: 'black',
    backgroundColor: '#fdfdfd',
    width: '100%',
    padding: 0,
    margin: 0
  },
  pageBreak: {
    marginTop: 10
  }
});

const MergedRow = () => <View style={styles.mergeRow}></View>;

const InvoiceItemsTable = ({
  paymentItems,
  remainAmount,
  listTax,
  forGroup,
  groupItems,
  transactionPayment,
  paidAmount,
  totalPrice
}: Props) => {
  const ITEMS_PER_PAGE_FIRST = 14;
  const ITEMS_PER_PAGE_OTHER = 25;

  const pages: PaymentItem[][] = [];
  if (paymentItems.length > 0) {
    pages.push(paymentItems.slice(0, ITEMS_PER_PAGE_FIRST));

    let startIndex = ITEMS_PER_PAGE_FIRST;
    while (startIndex < paymentItems.length) {
      pages.push(paymentItems.slice(startIndex, startIndex + ITEMS_PER_PAGE_OTHER));
      startIndex += ITEMS_PER_PAGE_OTHER;
    }
  }

  return (
    <View>
      {pages.map((pageItems, index) => (
        <View key={index} style={index > 0 ? styles.pageBreak : undefined} wrap={false}>
          <View style={styles.tableContainer}>
            <InvoiceTableHeader listTax={listTax} forGroup={forGroup} />
            <InvoiceTableRow
              items={pageItems}
              listTax={listTax}
              forGroup={forGroup}
              groupItems={groupItems}
            />
          </View>
          <MergedRow />
        </View>
      ))}
      <View style={styles.tableContainer} wrap={false}>
        <InvoiceTableFooter
          remainAmount={remainAmount}
          listTax={listTax}
          forGroup={forGroup}
          transactionPayment={transactionPayment}
          paidAmount={paidAmount}
          totalPrice={totalPrice}
        />
      </View>
    </View>
  );
};

export default InvoiceItemsTable;
