import { SUB_MENU_HEADER, MENU_HEADER } from 'routes/constants';
/**
 * Filters the sub-menu header based on permissions.
 * @param {Record<string, boolean>} permissions - The permissions object.
 * @param {(permissionCode: string) => boolean} hasPermission - Function to check if a permission is available.
 * @returns {{ filteredSubMenuHeader: typeof SUB_MENU_HEADER, filteredMenuHeader: typeof MENU_HEADER }}
 * - An object containing the filtered sub-menu header and menu header.
 */
export const getFilteredSubMenuHeaderAndMenuHeader = (
  permissions: Record<string, boolean>,
  hasPermission: (permissionCode: string) => boolean
): {
  filteredSubMenuHeader: typeof SUB_MENU_HEADER;
  filteredMenuHeader: typeof MENU_HEADER;
} => {
  if (!hasPermission || Object.keys(permissions).length === 0) {
    return { filteredSubMenuHeader: SUB_MENU_HEADER, filteredMenuHeader: MENU_HEADER };
  }

  const filteredSubMenuHeader = Object.keys(SUB_MENU_HEADER).reduce(
    (acc, key) => {
      const filteredSubMenu = SUB_MENU_HEADER[key].filter(menuItem => {
        return menuItem.permission ? hasPermission(menuItem.permission) : true;
      });
      if (filteredSubMenu.length > 0) {
        acc[key] = filteredSubMenu;
      }
      return acc;
    },
    {} as typeof SUB_MENU_HEADER
  );

  const filteredMenuHeader = MENU_HEADER.filter(menu => {
    const menuId = menu.id;

    if (MENU_HEADER[menuId]) {
      if (!SUB_MENU_HEADER[menuId] && !MENU_HEADER[menuId].children) return true;

      if (MENU_HEADER[menuId].children) {
        const reportItems = MENU_HEADER[menuId];

        if (reportItems) {
          const validChildren = reportItems.children?.filter(child => {
            if (filteredSubMenuHeader[child.id]?.length) {
              child.url = filteredSubMenuHeader[child.id][0]?.url;
              return true;
            } else {
              return child.permission ? hasPermission(child.permission) : true;
            }
          });

          if (validChildren && validChildren.length > 0) {
            reportItems.children = validChildren;
            return true;
          }
        }
      }
    }

    if (filteredSubMenuHeader[menuId] && filteredSubMenuHeader[menuId]?.length) {
      menu.url = filteredSubMenuHeader[menuId][0]?.url;
      return true;
    }

    return !!menu.children?.length;
  });

  return { filteredSubMenuHeader, filteredMenuHeader };
};
