import { DetailedAnalysisReport, GetDetailedAnalysisReport } from 'services/api/type/report.type';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import _ from 'lodash';
import { PaginationType } from 'services/api/type/booking.type';
import { PAGE_SIZE_DETAILED_ANALYSIS_REPORT } from 'services/api/module/report.api';

export const useGetDetailedAnalysisReport = (
  filterOptions: GetDetailedAnalysisReport,
  page: number,
  pageSize: number = PAGE_SIZE_DETAILED_ANALYSIS_REPORT
) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_DETAILED_ANALYSIS_REPORT, page],
    queryFn: () => API.report.detailedAnalysisReport({ ...filterOptions, page, limit: pageSize })
  });

  return {
    data: _.get(data, 'data', []) as DetailedAnalysisReport[],
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    isFetching
  };
};

export const useGetDetailedAnalysisReportForExport = (
  filterOptions: GetDetailedAnalysisReport,
  enable: boolean = false
) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_DETAILED_ANALYSIS_REPORT, filterOptions],
    queryFn: () => API.report.detailedAnalysisReport(filterOptions),
    enabled: enable
  });

  return {
    data: _.get(data, 'data', []) as DetailedAnalysisReport[],
    pagination: _.get(data, 'pagination', {}) as PaginationType,
    isFetching
  };
};
