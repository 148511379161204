import React, { createContext, useContext, ReactNode } from 'react';
import { useGetPermission } from 'hooks/useGetPermission';

type PermissionContextType = {
  permissions: Record<string, boolean>;
  isLoading: boolean;
  hasPermission: (permissionCode: string) => boolean;
};

const PermissionContext = createContext<PermissionContextType | undefined>(undefined);

export const usePermissions = () => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error('usePermissions must be used within a PermissionProvider');
  }
  return context;
};

export const PermissionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const enablePermission = JSON.parse(process.env.REACT_APP_ENABLE_PERMISSION || 'false');
  const { permissions, isLoading } = useGetPermission();

  const hasPermission = (permissionCode: string): boolean => {
    return permissions[permissionCode] || !enablePermission;
  };

  return (
    <PermissionContext.Provider value={{ permissions, isLoading, hasPermission }}>
      {children}
    </PermissionContext.Provider>
  );
};
