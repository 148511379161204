import { Button, Card, Checkbox, Dropdown, Empty, Tabs } from 'antd';
import TransactionTable from 'components/cashier/transaction-table';
import React, { useEffect, useState } from 'react';
import { CaretDownFilled, HistoryOutlined } from '@ant-design/icons';
import { TRANSACTION_PROCESSING_ACTION } from 'constants/cashier';
import useModal from 'stores/useModal';
import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { upperCase } from 'lodash';
import useCashierStore from 'stores/useCashier';
import { CustomMenuItem, PermissionActionTypes } from 'helper/type/action.type';
import { usePermissions } from 'AuthorizePermission';

import { useFilterActions } from 'helper/common/permission.action.helper';
import IBButton from 'helper/permission.action';

interface CashierTransactionProps {
  orders: any[];
  booking_line_id?: number;
  guest_id?: number;
}

export const CashierTransaction = (props: CashierTransactionProps) => {
  const { orders, guest_id, booking_line_id } = props;
  const {
    openPostRoomCharge,
    openMoveTransaction,
    openPostPaymentTransaction,
    openCancelTransactions,
    openPostRefundTransaction,
    openCreateInvoiceTransaction,
    setIsOpenTransactionHistory,
    openMoveSaleOrder,
    openUndoCancelledTransactions
  } = useModal();
  const { setIsOpenAddService } = useModalStore();
  const { setBookingLineId } = useBookingStore();
  const { setFolioBalanceCode } = useCashierStore();
  const [hideDeleted, setHideDeleted] = useState(true);
  const [cancelledCount, setCancelledCount] = useState(0);

  const [transactionIds, setTransactionIds] = useState<number[] | []>([]);
  const [cancelledTransactionIds, setCancelledTransactionIds] = useState<number[] | []>([]);
  const [code, setCode] = useState<string>('A');

  const { permissions, hasPermission } = usePermissions();

  const items: CustomMenuItem[] = [
    {
      key: TRANSACTION_PROCESSING_ACTION.VOID_TRANSACTION,
      label: 'Void Transaction',
      permission: PermissionActionTypes.VOID_TRANSACTION,
      disabled: transactionIds.length === 0
    },
    {
      key: TRANSACTION_PROCESSING_ACTION.MOVE_TRANSACTION,
      label: 'Move Transaction',
      permission: PermissionActionTypes.MOVE_TRANSACTION,
      disabled: transactionIds.length === 0
    },
    {
      key: TRANSACTION_PROCESSING_ACTION.UNDO_VOIDED,
      label: 'Undo Voided Transaction',
      disabled: cancelledTransactionIds.length === 0
    }
  ];

  const menuPermission = useFilterActions(items, permissions, hasPermission);

  // Initial code
  useEffect(() => {
    setCode(orders?.at(0)?.folio_balance_code || 'A');
  }, [orders, guest_id]);

  // with memo
  const currentOrder = React.useMemo(() => {
    return orders?.filter(item => item.folio_balance_code === code)?.at(0);
  }, [code, orders]);

  useEffect(() => {
    setHideDeleted(true);
  }, [currentOrder]);

  const handleShowAddService = () => {
    if (booking_line_id) {
      setBookingLineId(booking_line_id);
      setIsOpenAddService(true);
      setFolioBalanceCode(code);
    }
  };

  const postRoomCharge = () => {
    if (booking_line_id) {
      openPostRoomCharge(booking_line_id);
    }
  };

  const postPayment = () => {
    if (guest_id && code && booking_line_id && orders) {
      openPostPaymentTransaction(code, booking_line_id, guest_id, currentOrder?.id);
    }
  };

  const postRefund = () => {
    if (guest_id && code && booking_line_id && orders) {
      openPostRefundTransaction(code, booking_line_id, guest_id, currentOrder?.id);
    }
  };

  const handleCreateInvoice = () => {
    if (orders && guest_id && code && booking_line_id) {
      openCreateInvoiceTransaction(orders, guest_id, code, booking_line_id, true);
    }
  };

  const handleShowHistory = () => {
    if (currentOrder) {
      setIsOpenTransactionHistory(true, currentOrder.id);
    }
  };

  const handleMoveSaleOrder = () => {
    openMoveSaleOrder(Number(booking_line_id), Number(guest_id), Number(currentOrder.id));
  };

  const handleMenuClick = (menu: any) => {
    switch (menu.key) {
      case TRANSACTION_PROCESSING_ACTION.MOVE_TRANSACTION:
        if (guest_id && booking_line_id) {
          openMoveTransaction(transactionIds, booking_line_id, code, guest_id);
        }
        break;
      case TRANSACTION_PROCESSING_ACTION.VOID_TRANSACTION:
        openCancelTransactions(transactionIds);
        break;
      case TRANSACTION_PROCESSING_ACTION.UNDO_VOIDED:
        openUndoCancelledTransactions(cancelledTransactionIds);
        break;
    }
  };

  const updateCancelledCount = (count: number) => {
    setCancelledCount(count);
  };

  return (
    <Card className="pms-cashier__transaction">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <p className="title">Transaction List #{guest_id}</p>
        <Checkbox checked={hideDeleted} onChange={e => setHideDeleted(e.target.checked)}>
          Hide Cancelled Txs ({cancelledCount})
        </Checkbox>
      </div>
      {orders ? (
        <>
          <Tabs
            onChange={(code: string) => setCode(code)}
            items={orders.map((item: any) => ({
              key: item.folio_balance_code,
              label: `${item.folio_balance_code} - ${item.sale_order_name} ${item.state === 'invoiced' ? `(${upperCase(item.state)})` : ''}`,
              children: (
                <TransactionTable
                  orderId={item.id}
                  setTransactionIds={setTransactionIds}
                  setCancelledTransactionIds={setCancelledTransactionIds}
                  isInvoiced={item.state == 'invoiced'}
                  correctOrderId={currentOrder ? currentOrder.id : 0}
                  hideDeleted={hideDeleted}
                  onCancelledCountChange={updateCancelledCount}
                />
              )
            }))}
          ></Tabs>
        </>
      ) : (
        <Empty />
      )}
      <div className="text-right" style={{ marginTop: 12 }}>
        <Button.Group className="flex-wrap">
          <Button
            className="ant-btn-secondary"
            onClick={handleShowHistory}
            disabled={!currentOrder}
          >
            <HistoryOutlined />
            History
          </Button>
          <IBButton
            permission={PermissionActionTypes.POST_TRANSACTION}
            className="ant-btn-secondary"
            onClick={() => postRoomCharge()}
            disabled={!guest_id}
          >
            + Room Charge
          </IBButton>
          <IBButton
            permission={PermissionActionTypes.HK_SERVICE}
            className="ant-btn-secondary"
            onClick={() => handleShowAddService()}
            disabled={!guest_id}
          >
            + HK Service
          </IBButton>
          <IBButton
            permission={PermissionActionTypes.MOVE_SALE_ORDER}
            className="ant-btn-secondary"
            onClick={() => handleMoveSaleOrder()}
            disabled={!currentOrder || !guest_id || currentOrder?.state === 'invoiced'}
          >
            Move Sale Order
          </IBButton>

          <Dropdown
            menu={{ items: menuPermission, onClick: handleMenuClick }}
            placement="top"
            trigger={['click']}
            disabled={
              (transactionIds.length === 0 && cancelledTransactionIds.length === 0) ||
              currentOrder?.state === 'invoiced'
            }
          >
            <Button className="ant-btn-secondary">
              Trans Processing <CaretDownFilled />
            </Button>
          </Dropdown>
          <IBButton
            permission={PermissionActionTypes.PAYMENT}
            className="ant-btn-secondary"
            onClick={() => postPayment()}
            disabled={!guest_id}
          >
            Payment
          </IBButton>
          <IBButton
            permission={PermissionActionTypes.REFUND}
            className="ant-btn-secondary"
            onClick={() => postRefund()}
            disabled={!guest_id || currentOrder?.state === 'invoiced'}
          >
            Refund
          </IBButton>
          <IBButton
            permission={PermissionActionTypes.INVOICE}
            className="ant-btn-secondary"
            onClick={() => handleCreateInvoice()}
            disabled={!currentOrder?.sale_order_name}
          >
            Invoice
          </IBButton>
        </Button.Group>
      </div>
    </Card>
  );
};
