import React, { useMemo } from 'react';
import _ from 'lodash';
import {
  Text,
  View,
  Page,
  Document,
  StyleSheet,
  Svg,
  Path,
  Font,
  Image
} from '@react-pdf/renderer';

import InvoiceItemsTable from './invoice-table';

import { BookingLine, TransactionPayment } from 'services/api/type/booking.type';
import { MyInfoType } from 'services/api/type/user.type';
import { Payment } from 'services/api/type/payment.type';
import { PAYMENT_FOR, PAYMENT_METHOD_NAMES, PAYMENT_STATUS } from 'constants/form';
import { toLocalTime } from 'utils';
import { currencyFormatter } from 'utils/currency';
import { CHARGE_TYPE } from '../modal/bill-pdf-modal';
import { BranchesType } from 'services/api/type/branch.type';

// Register font
Font.register({
  family: 'Inter',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyeMZhrib2Bg-4.ttf',
      fontWeight: 100
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf',
      fontWeight: 200
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuOKfMZhrib2Bg-4.ttf',
      fontWeight: 300
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
      fontWeight: 400
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
      fontWeight: 500
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
      fontWeight: 600
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
      fontWeight: 700
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf',
      fontWeight: 800
    },
    {
      src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuBWYMZhrib2Bg-4.ttf',
      fontWeight: 900
    }
  ]
});

const styles = StyleSheet.create({
  page: {
    fontSize: 12,
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    lineHeight: 1.5,
    flexDirection: 'column'
  },

  spaceBetween: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000'
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px'
  },

  image: {
    width: 60,
    height: 50,
    marginTop: 10,
    marginLeft: 40
  },

  titleContainer: { flexDirection: 'row', marginTop: 8, marginBottom: 16 },
  billContainer: { flexDirection: 'column', paddingBottom: '12px' },
  billRow: {
    flexDirection: 'row',
    columnGap: '108px'
  },
  billCol: {
    flexDirection: 'column',
    gap: '8px',
    justifyContent: 'flex-start'
  },
  billColItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    columnGap: '12px'
  },
  billRowItem: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: '8px',
    marginTop: '8px'
  },

  totalRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: '#000'
  },

  signatureContainer: {
    flexDirection: 'row',
    marginTop: 28,
    justifyContent: 'space-evenly'
  },
  signatureItem: {
    flexDirection: 'column',
    rowGap: '8px'
  },

  logo: { width: 90 },
  text: { fontSize: 11, fontFamily: 'Inter' },
  textExSmallBold: { fontSize: 11, fontWeight: 600, fontFamily: 'Inter' },
  textBold: { fontSize: 14, fontWeight: 600, fontFamily: 'Inter' },
  textSmallBold: { fontSize: 12, fontWeight: 600, fontFamily: 'Inter' }
});

interface Props {
  bookingLineDetail: BookingLine | undefined;
  payments: Payment[] | undefined;
  me: MyInfoType | undefined;
  dataChange: any;
  signatureStaff: string;
  signatureGuest: string;
  currentBranch: BranchesType | undefined;
  transactionPayment: TransactionPayment[] | undefined;
}

const SERVICE_FEE = 0.05;
// const TAX = 0.08;

export default function BillPDF({
  bookingLineDetail,
  payments,
  dataChange,
  signatureStaff,
  signatureGuest,
  currentBranch,
  transactionPayment
}: Props) {
  const Logo = () => (
    <Svg width="71" height="57" viewBox="0 0 71 57" fill="none">
      <Path
        d="M27.4297 10.9824C29.3562 10.9824 30.918 9.39243 30.918 7.43109C30.918 5.46974 29.3562 3.87976 27.4297 3.87976C25.5032 3.87976 23.9414 5.46974 23.9414 7.43109C23.9414 9.39243 25.5032 10.9824 27.4297 10.9824Z"
        fill="#2E3E54"
      />
      <Path
        d="M32.0671 41.665V19.8676C32.0671 14.4051 29.1785 13.5126 29.1785 13.5126H22.3688C22.3688 13.5126 25.2574 14.3525 25.2574 19.8676V41.665C25.2574 47.1275 28.146 48.02 28.146 48.02H34.9557C34.9557 48.02 32.0671 47.1801 32.0671 41.665Z"
        fill="#2E3E54"
      />
      <Path
        d="M56.7265 13.5125H51.6014V34.97C48.406 24.9959 43.1071 22.4329 43.1071 16.8734C43.1071 14.2473 44.3449 13.5114 44.3449 13.5114H36.2974V48.0211H37.226V19.9201C40.3211 30.3194 49.9168 36.8859 49.9168 44.7116C49.9168 47.1275 48.7817 48.0211 48.7817 48.0211H56.7265V13.5125Z"
        fill="#2E3E54"
      />
      <Path
        d="M23.7174 41.4252C23.7174 30.8553 16.8669 29.8775 12.597 30.2385C12.6915 30.117 12.7685 30.0177 12.779 30.0014L19.9877 19.8205C21.8321 17.2154 21.0283 15.5893 20.9933 15.5215C20.9863 15.5075 20.977 15.4946 20.9653 15.483L18.3509 12.7552C18.2937 12.6956 18.2039 12.6886 18.1385 12.7377C18.0732 12.7868 18.0534 12.8779 18.0907 12.9503C18.0977 12.9655 18.8409 14.4538 17.1084 16.9L10.1657 26.7035C10.1552 23.623 10.1552 19.845 10.1552 19.7212C10.1552 14.2821 7.27822 13.3931 7.27822 13.3931H0.5C0.5 13.3931 3.37578 14.2295 3.37578 19.7212V41.4252C3.37578 46.8643 6.25274 47.7533 6.25274 47.7533H13.0333C13.0333 47.7533 10.1563 46.9169 10.1563 41.4252V32.1158C12.1595 31.8541 16.9369 32.1684 16.9369 41.4252C16.9369 46.8643 19.8127 47.7533 19.8127 47.7533H26.5932C26.5932 47.7533 23.7163 46.9169 23.7163 41.4252H23.7174Z"
        fill="#2E3E54"
      />
      <Path
        d="M50.4709 0.5C44.3157 0.5 38.8103 2.99995 35.1365 6.93328C38.5256 3.8796 43.3392 2.18338 48.5436 2.60743C57.8348 3.36326 64.9747 10.6715 64.9747 18.9692V53.4043L30.4431 56.3014V56.5H70.4999V18.5312C70.4988 8.57343 61.5319 0.5 50.4709 0.5Z"
        fill="#2E3E54"
      />
    </Svg>
  );

  const HotelInfo = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={{ ...styles.textBold, textTransform: 'uppercase' }}>
            {currentBranch?.name}
          </Text>
          <Text style={styles.text}>Address: {currentBranch?.address}</Text>
          <Text style={styles.text}>
            {currentBranch?.phone_contact ? `Tel: ${currentBranch?.phone_contact}` : ''}
          </Text>
        </View>
        <Logo />
      </View>
    </View>
  );

  const BillInfo = () => (
    <View style={styles.billContainer}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '16px'
        }}
      >
        <Text style={{ ...styles.textBold, fontSize: 20 }}>HÓA ĐƠN</Text>
        <Text style={{ fontSize: 14 }}>(INVOICE)</Text>
      </View>

      <View style={styles.billRow}>
        <View style={{ ...styles.billCol, width: '40%' }}>
          <View style={styles.billColItem}>
            <Text style={styles.text}>Ngày (Date):</Text>
            <Text style={styles.textExSmallBold}>
              {dataChange?.createdDate?.format('DD/MM/YYYY')}
            </Text>
          </View>
        </View>
        <View style={{ ...styles.billCol, width: '60%' }}>
          <View style={styles.billColItem}>
            <Text style={{ ...styles.text, width: '65%' }}>Số tham chiếu (Folio No.):</Text>
            <Text style={{ ...styles.textExSmallBold, width: '35%' }}>
              {bookingLineDetail?.sale_order_name}
            </Text>
          </View>
          <View style={styles.billColItem}>
            <Text style={{ ...styles.text, width: '65%' }}>Số phòng (Room No.):</Text>
            <Text style={{ ...styles.textExSmallBold, width: '35%' }}>
              {bookingLineDetail?.room_name}
            </Text>
          </View>
        </View>
      </View>

      <View style={styles.billRowItem}>
        <Text style={{ ...styles.text }}>Tên khách hàng (Guest Name):</Text>
        <Text style={{ ...styles.textExSmallBold }}>
          {dataChange?.partnerName || bookingLineDetail?.partner_name}
        </Text>
      </View>

      <View style={styles.billRowItem}>
        <Text style={{ ...styles.text }}>Công ty (Company):</Text>
        <Text style={{ ...styles.textExSmallBold }}>{dataChange?.company}</Text>
      </View>

      <View style={styles.billRowItem}>
        <Text style={{ ...styles.text }}>Địa chỉ (Address):</Text>
        <Text style={{ ...styles.textExSmallBold }}>{dataChange?.companyAddress}</Text>
      </View>

      <View style={{ ...styles.billRow, marginTop: '8px' }}>
        <View style={{ ...styles.billCol, width: '47%' }}>
          <View style={styles.billColItem}>
            <Text style={styles.text}>Mã số thuế (Tax Code):</Text>
            <Text style={styles.textExSmallBold}>{dataChange?.taxCode}</Text>
          </View>
        </View>
        <View style={{ ...styles.billCol, width: '53%' }}>
          <View style={styles.billColItem}>
            <Text style={{ ...styles.text, width: '65%' }}>Ngày đến (Arrival):</Text>
            <Text style={{ ...styles.textExSmallBold, width: '35%' }}>
              {bookingLineDetail?.check_in
                ? toLocalTime(bookingLineDetail?.check_in, 'DD/MM/YYYY')
                : ''}
            </Text>
          </View>
          <View style={styles.billColItem}>
            <Text style={{ ...styles.text, width: '65%' }}>Ngày đi (Departure):</Text>
            <Text style={{ ...styles.textExSmallBold, width: '35%' }}>
              {bookingLineDetail?.check_out
                ? toLocalTime(bookingLineDetail?.check_out, 'DD/MM/YYYY')
                : ''}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );

  const Signature = () => (
    <View style={styles.signatureContainer}>
      <View style={styles.signatureItem}>
        <Text style={styles.textSmallBold}>Khách hàng (Customer):</Text>
        <Text style={styles.text}>Ký và ghi rõ họ tên (Sign and full name)</Text>
        {signatureGuest && <Image style={styles.image} src={signatureGuest} />}
      </View>
      <View style={styles.signatureItem}>
        <Text style={styles.textSmallBold}>Thu ngân (Cashier):</Text>
        <Text style={styles.text}>Ký và ghi rõ họ tên (Sign and full name)</Text>
        {signatureStaff && <Image style={styles.image} src={signatureStaff} />}
      </View>
    </View>
  );

  const totalPaidAmount = useMemo(() => {
    return (
      transactionPayment?.reduce((cur, next) => {
        return cur + next.amount;
      }, 0) || 0
    );
  }, [transactionPayment]);

  const paymentItems = useMemo(() => {
    const { chargeType } = dataChange;

    const taxBooking = (bookingLineDetail?.tax || 0) / 100;

    let bookingPrices =
      bookingLineDetail?.booking_prices.map(item => {
        const subAmount = Math.ceil(item.final_price / (1 + SERVICE_FEE + taxBooking));
        const serviceFee = Math.ceil(subAmount * SERVICE_FEE);
        const tax = Math.ceil(subAmount * taxBooking);
        return {
          date: toLocalTime(item.start_date, 'DD/MM/YYYY'),
          detail: `Tiền phòng (Room charge)`,
          price: subAmount.toLocaleString('vn'),
          serviceFee: serviceFee.toLocaleString('vn'),
          [`tax-${bookingLineDetail.tax}`]: tax.toLocaleString('vn'),
          amount: item.final_price.toLocaleString('vn')
        };
      }) || [];
    if (chargeType === CHARGE_TYPE.SERVICE) {
      bookingPrices = [];
    }

    let services: any[] = [];
    if (chargeType === CHARGE_TYPE.ALL || chargeType === CHARGE_TYPE.SERVICE) {
      services =
        bookingLineDetail?.services
          ?.filter(item => !item.is_deleted)
          .map(item => {
            const taxService = (item.tax || 0) / 100;
            const subAmount = Math.ceil(item.total_price / (1 + SERVICE_FEE + taxService));
            const serviceFee = Math.ceil(subAmount * SERVICE_FEE);
            const tax = Math.ceil(subAmount * taxService);
            return {
              date: toLocalTime(item.created_date, 'DD/MM/YYYY'),
              detail: item.name,
              price: subAmount.toLocaleString('vn'),
              qty: item.qty,
              serviceFee: serviceFee.toLocaleString('vn'),
              [`tax-${item.tax}`]: tax.toLocaleString('vn'),
              amount: item.total_price.toLocaleString('vn')
            };
          }) || [];
    }

    const filteredPayments =
      payments
        ?.filter(item => {
          let andCondition = true;
          if (chargeType === CHARGE_TYPE.ROOM) {
            andCondition = item.income_type === PAYMENT_FOR.ROOM;
          } else if (chargeType === CHARGE_TYPE.SERVICE) {
            andCondition = item.income_type === PAYMENT_FOR.SERVICE;
          }
          return item.state !== PAYMENT_STATUS.CANCELLED && andCondition;
        })
        .map(payment => ({
          date: toLocalTime(payment.payment_date, 'DD/MM/YYYY'),
          detail: `Thanh toán ${PAYMENT_METHOD_NAMES[payment.payment_method]}`,
          price: '',
          qty: '',
          serviceFee: '',
          tax: '',
          amount: `-${payment.amount?.toLocaleString('vn')}`
        })) || [];

    const result = [...bookingPrices, ...services, ...filteredPayments];

    return result;
    // eslint-disable-next-line
  }, [bookingLineDetail, payments, dataChange]);

  const totalPayment = useMemo(() => {
    const { chargeType } = dataChange;
    const totalPayment = payments
      ?.filter(item => {
        let andCondition = true;
        if (chargeType === CHARGE_TYPE.ROOM) {
          andCondition = item.income_type === PAYMENT_FOR.ROOM;
        } else if (chargeType === CHARGE_TYPE.SERVICE) {
          andCondition = item.income_type === PAYMENT_FOR.SERVICE;
        }
        return item.state !== PAYMENT_STATUS.CANCELLED && andCondition;
      })
      .reduce((cur, next) => {
        return cur + next.amount;
      }, 0);

    return totalPayment;
  }, [dataChange, payments]);

  const totalPrice = useMemo(() => {
    const { chargeType } = dataChange;
    let total = 0;
    const totalBookingPrice =
      bookingLineDetail?.booking_prices.reduce((cur, next) => {
        return cur + next.final_price;
      }, 0) || 0;

    const totalServicePrice =
      bookingLineDetail?.services?.reduce((cur, next) => {
        return cur + next.total_price;
      }, 0) || 0;

    switch (chargeType) {
      case CHARGE_TYPE.ALL:
        total = bookingLineDetail?.total_price || 0;
        break;
      case CHARGE_TYPE.ROOM:
        total = totalBookingPrice;
        break;
      case CHARGE_TYPE.SERVICE:
        total = totalServicePrice;
        break;
      default:
        break;
    }
    return total;
  }, [
    bookingLineDetail?.booking_prices,
    bookingLineDetail?.services,
    bookingLineDetail?.total_price,
    dataChange
  ]);

  const listTax = useMemo(() => {
    const result = {};
    const { chargeType } = dataChange;

    if (!_.isEmpty(bookingLineDetail)) {
      if (
        _.isEmpty(_.get(result, bookingLineDetail?.tax, '')) &&
        [CHARGE_TYPE.ALL, CHARGE_TYPE.ROOM].includes(chargeType)
      ) {
        _.set(result, bookingLineDetail?.tax, true);
      }

      if ([CHARGE_TYPE.ALL, CHARGE_TYPE.SERVICE].includes(chargeType)) {
        bookingLineDetail?.services
          ?.filter(item => !item.is_deleted)
          .forEach(item => {
            if (_.isEmpty(_.get(result, item.tax, ''))) {
              _.set(result, item.tax, true);
            }
          });
      }
    }
    return result;
  }, [bookingLineDetail, dataChange]);

  return (
    <Document title="Hóa đơn" language="vi">
      <Page wrap size="A4" style={styles.page}>
        <HotelInfo />
        <BillInfo />

        <InvoiceItemsTable
          listTax={listTax}
          paymentItems={paymentItems}
          remainAmount={totalPrice - Math.abs(Number(totalPayment) + totalPaidAmount || 0)}
          transactionPayment={transactionPayment}
          paidAmount={totalPaidAmount}
          totalPrice={
            totalPrice.toLocaleString('vn') || bookingLineDetail?.total_price?.toLocaleString('vn')
          }
        />

        <View
          break={paymentItems.length >= 8}
          style={{
            ...styles.totalRow,
            marginTop: '12px',
            width: '80%'
          }}
        >
          <Text style={styles.text}>Tổng cộng tiền thanh toán (Total Amount):</Text>
          <Text style={{ ...styles.text, fontSize: 12 }}>
            {totalPrice.toLocaleString('vn') ||
              bookingLineDetail?.total_price?.toLocaleString('vn')}
          </Text>
        </View>

        <View style={{ ...styles.flex, marginTop: '6px' }}>
          <Text style={styles.text}>Số tiền bằng chữ (Amount in words):</Text>
          <Text style={styles.text}>
            {currencyFormatter(totalPrice) ||
              (bookingLineDetail?.total_price
                ? currencyFormatter(bookingLineDetail?.total_price)
                : '')}
          </Text>
        </View>

        <Signature />
      </Page>
    </Document>
  );
}
