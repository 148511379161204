import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import {
  Modal,
  Row,
  Col,
  notification,
  Form,
  FormProps,
  Select,
  InputNumber,
  Checkbox
  // Checkbox,
} from 'antd';
import { CheckCircleFilled, DownloadOutlined } from '@ant-design/icons';

import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';
import API from 'services/api';

import { ReactComponent as IconCancelCalendar } from 'assets/images/cancel-calendar.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import { BookingPrice, BookingStatus, ParamsChangePrices } from 'services/api/type/booking.type';
import { formatCurrency, toLocalTime } from 'utils';
import QUERY_KEYS from 'services/api/queryKeys';
import { refreshBookingList, refreshRoomMap } from 'hooks';
import { formatInputNumber } from 'utils/currency';
import { queryClient } from 'index';

function ChangePriceModal() {
  const [form] = Form.useForm();
  const location = useLocation();
  const bookingPriceForm = Form.useWatch('booking_prices', form);

  const { bookingLineId } = useBookingStore();
  const { isOpenChangePrice, setIsOpenChangePrice } = useModal();

  const { data: bookingLineDetail } = useGetBookingDetail(bookingLineId);

  const { data: availRoomTypes } = useGetAvailRoomType(
    bookingLineDetail?.check_in,
    bookingLineDetail?.check_out,
    isOpenChangePrice
  );
  const [priceAll, setPriceAll] = useState<number | undefined | null>();

  const { mutateAsync: mutateChangePrice, isPending } = useMutation({
    mutationFn: ({
      bookingLineId,
      params
    }: {
      bookingLineId: number;
      params: ParamsChangePrices;
    }) => API.booking.changePrices(bookingLineId, params)
  });

  useEffect(() => {
    if (!isOpenChangePrice) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        booking_prices: bookingLineDetail?.booking_prices,
        pricelist_id: bookingLineDetail?.pricelist?.id || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenChangePrice]);

  const handleCloseModal = () => {
    setIsOpenChangePrice(false);
  };

  const handleChangePriceAll = (value: number | undefined | null) => {
    setPriceAll(value);
    const bookingPrices: BookingPrice[] = _.cloneDeep(form.getFieldValue('booking_prices'));
    bookingPrices.forEach(item => {
      item.price = Number(value);
    });
    form.setFieldValue('booking_prices', bookingPrices);
  };

  const handleChangePrice = () => {
    setPriceAll(null);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const params: any = {
        prices: (values.booking_prices as BookingPrice[]).map(item => ({
          id: item.id,
          price: item.price
        }))
      };
      if (values.pricelist_id) {
        params.pricelist_id = values.pricelist_id;
      }

      await mutateChangePrice({
        bookingLineId: Number(bookingLineDetail?.booking_line_id),
        params
      });

      notification.success({
        message: 'Đổi giá thành công'
      });

      await queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEYS.KEY_ROOM,
          QUERY_KEYS.GET_BOOKING_DETAIL,
          bookingLineDetail?.booking_line_id
        ]
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRANSACTION_HISTORY_LOGS]
      });

      refreshBookingList(location);
      refreshRoomMap(location);

      setIsOpenChangePrice(false);
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const pricingList = useMemo(() => {
    const roomType = _.find(availRoomTypes, {
      room_type_name: bookingLineDetail?.room_type_name
    });
    if (roomType) {
      return [
        {
          label: `Giá mặc định`,
          value: ''
        },
        ...roomType.pricing.map(item => ({
          label: item.pricelist_name,
          value: item.pricelist_id
        }))
      ];
    }
    return [];
  }, [availRoomTypes, bookingLineDetail?.room_type_name]);

  const handleSelectPricing = (priceListId: number) => {
    setPriceAll(null);
    const roomType = _.find(availRoomTypes, {
      room_type_name: bookingLineDetail?.room_type_name
    });
    if (roomType) {
      const priceObj = _.find(roomType.pricing, { pricelist_id: priceListId });
      let bookingPrices: BookingPrice[] = _.cloneDeep(form.getFieldValue('booking_prices'));
      if (priceObj) {
        bookingPrices.forEach(item => {
          item.price = priceObj?.price;
        });
      } else {
        bookingPrices = bookingPrices.map(item => ({
          ...item,
          price: roomType.price
        }));
      }
      form.setFieldValue('booking_prices', bookingPrices);
    }
  };

  const totalPrice = useMemo(() => {
    if (!bookingPriceForm) {
      return 0;
    }
    return (bookingPriceForm as BookingPrice[]).reduce((cur, next) => {
      return cur + next.price;
    }, 0);
  }, [bookingPriceForm]);

  return (
    <Modal
      title={
        <div className="modal-change-price-header">
          <h3 className="modal-change-price-header__title">Đổi giá</h3>
          <div className="modal-change-price-header__info">
            <div className="flex items-center" style={{ gap: '4px' }}>
              {bookingLineDetail?.status === BookingStatus.CHECK_IN ? (
                <IconBedUser />
              ) : (
                <IconCancelCalendar />
              )}
              <span style={{ lineHeight: 'normal' }}>
                {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
              </span>
            </div>

            <p className="modal-change-price-header__customer-name">
              {bookingLineDetail?.partner_name || 'Guest'}
            </p>
          </div>
        </div>
      }
      centered
      width={760}
      open={isOpenChangePrice}
      onCancel={handleCloseModal}
      okText="Lưu"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText="Đóng"
      closeIcon={null}
      className={classNames('modal-change-price')}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="change-price-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          booking_prices: [],
          pricelist_id: ''
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row>
          <Col span={24} md={12} className="change-price-col">
            <div className="change-price-preset">
              <Row>
                <Col span={12} className="flex items-center">
                  <b>
                    {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
                  </b>
                </Col>
                <Col span={12}>
                  <Form.Item name="pricelist_id" className="m-0 w-full">
                    <Select options={pricingList} onChange={handleSelectPricing} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div style={{ marginBottom: '8px' }}>
              <InputNumber
                min={0}
                value={priceAll}
                onChange={handleChangePriceAll}
                placeholder="Cập nhật giá phòng hàng loại"
                prefix={<DownloadOutlined style={{ color: '#808080' }} />}
                className="w-full"
                formatter={value => formatInputNumber(value)}
              />
            </div>

            <Form.List name="booking_prices">
              {fields => {
                return (
                  <div className="modal-change-price__list">
                    {fields.map(field => {
                      const date = form.getFieldValue(['booking_prices', field.name, 'start_date']);
                      const isPosted = form.getFieldValue([
                        'booking_prices',
                        field.name,
                        'is_posted'
                      ]);
                      const currentDayOfWeek = dayjs(date).day();

                      return (
                        <div className="price-item" key={field.name}>
                          <Row>
                            <Col span={8} className="flex items-center">
                              <span>
                                T{currentDayOfWeek + 1} {toLocalTime(date, 'DD/MM')}{' '}
                                {isPosted && (
                                  <span
                                    style={{
                                      display: 'inline-block',
                                      padding: '1px',
                                      lineHeight: '1',
                                      verticalAlign: 'baseline',
                                      color: 'green'
                                    }}
                                  >
                                    <CheckCircleFilled />
                                  </span>
                                )}
                              </span>
                            </Col>
                            <Col span={4}>
                              <Form.Item
                                className="m-0"
                                name={[field.name, 'enabled']}
                                valuePropName="checked"
                                initialValue={false}
                              >
                                <Checkbox disabled={!isPosted} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item name={[field.name, 'price']} className="m-0">
                                <InputNumber
                                  prefix="VND"
                                  className="w-full"
                                  formatter={value =>
                                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                                  }
                                  onChange={handleChangePrice}
                                  disabled={
                                    isPosted &&
                                    !form.getFieldValue(['booking_prices', field.name, 'enabled'])
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            </Form.List>

            <div className="total-wrapper">
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <span className="text-total">Tổng</span>
                </Col>
                <Col span={12}>
                  <span className="text-total">{formatCurrency(totalPrice, 'VND')}</span>
                </Col>
              </Row>
            </div>
          </Col>

          <Col span={24} md={12} className="change-price-col">
            {/* <Row gutter={[16, 8]}>
              <Col span={12}>
                <Form.Item label="Giảm giá" name="salePercent" className="m-0">
                  <InputNumber min={0} max={100} prefix="%" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Giảm giá (Số tiền)"
                  name="salePrice"
                  className="m-0"
                >
                  <InputNumber min={0} prefix="VND" className="w-full" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="freeFee"
              valuePropName="checked"
              style={{ marginTop: "12px", marginBottom: "12px" }}
            >
              <Checkbox>Miễn phí tiền phòng</Checkbox>
            </Form.Item>

            <p className="sale-note">
              Giảm giá % áp dụng cho tiền phòng (+ phụ thu)
            </p>
            <p className="sale-note">
              Giảm giá % áp dụng trước giảm giá số tiền
            </p> */}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

export default ChangePriceModal;
