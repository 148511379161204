import React, { useEffect } from 'react';
import {
  Button,
  Col,
  Flex,
  Form,
  FormProps,
  Input,
  InputNumber,
  notification,
  Radio,
  Row,
  Select
} from 'antd';
import { buildClassName, formatCurrency, toLocalTime } from 'utils';
import {
  PlusOutlined,
  PrinterFilled,
  CloseCircleFilled,
  CheckCircleFilled
} from '@ant-design/icons';
import {
  PAYMENT_FOR,
  PAYMENT_FOR_NAMES,
  PAYMENT_FOR_OPTIONS,
  PAYMENT_METHOD,
  PAYMENT_METHOD_NAMES,
  PAYMENT_METHOD_OPTIONS,
  PAYMENT_STATUS,
  PAYMENT_TYPE
} from 'constants/form';
import { PrinterOutlined } from '@ant-design/icons';
import { BookingLine, BookingStatus } from 'services/api/type/booking.type';
import { useGetPayments } from 'hooks/useGetPayments';
import { useMutation } from '@tanstack/react-query';
import { makePayment } from 'services/api/module/payment.api';
import { MakePayment } from 'services/api/type/payment.type';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { axiosInstance } from 'services/api/base.api';
import classNames from 'classnames';
import { formatInputNumber } from 'utils/currency';
import { useGetBookingDiscount } from 'hooks/useGetBookingDiscount';

import { useNavigate } from 'react-router-dom';
import PAGES from 'routes/constants';
import useCashierStore from 'stores/useCashier';
import AddDiscount from 'pages/discount-info';
import useCommonStore from 'stores/useCommon';

import { PermissionActionTypes } from 'helper/type/action.type';
import IBButton from 'helper/permission.action';

interface PaymentTabProps {
  bookingLine: BookingLine;
}

const updatedPaymentMethodOptions = [
  ...PAYMENT_METHOD_OPTIONS,
  {
    label: PAYMENT_METHOD_NAMES[PAYMENT_METHOD.DEBT],
    value: PAYMENT_METHOD.DEBT
  }
];

const PaymentTab = ({ bookingLine }: PaymentTabProps) => {
  const [form] = Form.useForm();
  const {
    setIsOpenAddService,
    setIsOpenViewPDF,
    setIsOpenConfirmDeletePayment,
    setIsOpenViewChargeDebtPayment,
    setOpenBookingDetail,
    setIsOpenAddDiscount
  } = useModalStore();
  const { setSelectedReservationId } = useCashierStore();
  const { setBookingLineId } = useBookingStore();
  const { currentBranch } = useCommonStore();

  const navigate = useNavigate();
  const payment_type = Form.useWatch('payment_type', form);

  const { data } = useGetPayments(bookingLine.booking_line_id);
  const { data: BookingDiscount } = useGetBookingDiscount(bookingLine.booking_line_id);
  const { mutateAsync: mutateMakePayment, isPending: isPendingMakePayment } = useMutation({
    mutationFn: (data: MakePayment) => {
      return makePayment(bookingLine.booking_line_id, data);
    }
  });

  useEffect(() => {
    const remain = bookingLine.total_price - bookingLine.paid_amount;
    if (payment_type === PAYMENT_TYPE.EXPENSE) {
      form.setFieldValue('amount', Math.abs(remain));
    }
    if (payment_type === PAYMENT_TYPE.INCOME) {
      form.setFieldValue('amount', remain > 0 ? remain : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, payment_type, bookingLine]);

  const onFinishPayment: FormProps['onFinish'] = async (values: MakePayment) => {
    try {
      await mutateMakePayment({
        amount: values.amount,
        note: values.note,
        payment_method: values.payment_method,
        payment_type: values.payment_type,
        income_type: values.income_type
      });

      form.resetFields();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_PAYMENTS, bookingLine.booking_line_id]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const goToInvoice = (invoiceId: number) => {
    axiosInstance.get(`/invoices/${invoiceId}/generate-url`).then(res => {
      window.open(res.data.data.url_pdf, '_blank');
    });
  };

  const handleViewPdf = () => {
    setIsOpenViewPDF(true);
  };

  const handleViewChargeDebtPayment = (payment_id: number, amount: number, state: string) => {
    setIsOpenViewChargeDebtPayment(true, payment_id, amount, state);
  };

  const handleShowAddService = () => {
    setBookingLineId(bookingLine.booking_line_id);
    setIsOpenAddService(true);
  };

  const handleRemovePayment = (payment_id: number) => {
    setIsOpenConfirmDeletePayment(true, payment_id);
  };

  const handleShowAddDiscount = () => {
    setIsOpenAddDiscount(true);
  };

  const isConfirmOrCheckIn = [BookingStatus.CHECK_IN, BookingStatus.CONFIRM].includes(
    bookingLine.status
  );
  const isCancelOrCheckOut = [BookingStatus.CHECK_OUT, BookingStatus.CANCEL].includes(
    bookingLine.status
  );

  const handleRedirectToCashier = () => {
    setOpenBookingDetail(false);
    setSelectedReservationId(String(bookingLine.booking_line_id));
    navigate(PAGES.cashier);
  };

  const openDiscount: boolean = false;

  return (
    <Row className="modal-content">
      <Col span={24} md={14}>
        <div className="modal-content__left">
          <div
            className="modal-content__left-title"
            style={{
              border: bookingLine.status === BookingStatus.CHECK_OUT ? 'none' : ''
            }}
          >
            {bookingLine.status === BookingStatus.CHECK_OUT ? (
              <>
                <h4>Phòng đã check out</h4>
                <Button
                  className="ant-btn-secondary"
                  icon={<PrinterFilled />}
                  onClick={handleViewPdf}
                >
                  Xem hóa đơn
                </Button>
              </>
            ) : (
              bookingLine.sale_order_name && (
                <>
                  <h4>Hóa đơn #{bookingLine.sale_order_name}</h4>
                  <h4>
                    {formatCurrency(
                      bookingLine.status === BookingStatus.CANCEL
                        ? bookingLine.cancel_price
                        : bookingLine.total_price
                    )}
                  </h4>
                </>
              )
            )}
          </div>

          {isConfirmOrCheckIn && (
            <>
              <div className="modal-content__left-room-fee">
                <Row>
                  <Col span={24} md={6}>
                    <span>Tiền phòng</span>
                  </Col>
                  <Col span={24} md={18}>
                    {bookingLine.booking_prices?.map((booking_price, index) => (
                      <Row key={index}>
                        <Col span={24} md={18}>
                          <p className="m-0">
                            Giá ngày ({toLocalTime(booking_price.start_date, 'DD/MM HH:mm')} -{' '}
                            {toLocalTime(booking_price.end_date, 'DD/MM HH:mm')}){' '}
                            {booking_price.is_posted && (
                              <span
                                style={{
                                  display: 'inline-block',
                                  padding: '1px',
                                  lineHeight: '1',
                                  verticalAlign: 'baseline',
                                  color: 'green'
                                }}
                              >
                                <CheckCircleFilled />
                              </span>
                            )}
                          </p>
                        </Col>
                        <Col span={24} md={6}>
                          <p className="m-0 text-right">{formatCurrency(booking_price.price)}</p>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </div>

              <div className="modal-content__left-add-service">
                <Row>
                  <Col span={24} md={6}>
                    <IBButton
                      permission={PermissionActionTypes.ADD_SERVICE}
                      className="ant-btn-secondary"
                      onClick={handleShowAddService}
                      icon={<PlusOutlined style={{ color: '#fff' }} />}
                    >
                      Dịch vụ
                    </IBButton>
                  </Col>
                  <Col span={24} md={18}>
                    {bookingLine.services?.map(service => (
                      <Row
                        key={service.id}
                        className={buildClassName({
                          'line-through': service.is_deleted
                        })}
                      >
                        <Col span={24} md={18}>
                          <p className="m-0">
                            {service.name} ({service.qty})
                            {service.note && <span className="service-note">- {service.note}</span>}
                          </p>
                        </Col>
                        <Col span={24} md={6}>
                          <p className="m-0 text-right">{formatCurrency(service.subtotal_price)}</p>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </div>

              <div className="modal-content__left-commission">
                {currentBranch?.is_enable_v2 && openDiscount ? (
                  <Row>
                    <Col span={24} md={6}>
                      <Button
                        className="ant-btn-secondary"
                        onClick={handleShowAddDiscount}
                        icon={<PlusOutlined style={{ color: '#fff' }} />}
                        disabled={openDiscount}
                      >
                        Chiết khấu
                      </Button>
                      <AddDiscount bookingLineDetail={bookingLine} />
                    </Col>
                    <Col span={24} md={14}>
                      <span>{BookingDiscount?.discount_reason}</span>
                    </Col>
                    <Col span={24} md={4}>
                      <p className="m-0 required text-right">
                        {formatCurrency(BookingDiscount?.discount_total)}
                      </p>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col span={24} md={6}>
                      <p className="m-0">Chiết khấu</p>
                    </Col>
                    <Col span={24} md={14}>
                      <span>0</span>
                    </Col>
                    <Col span={24} md={4}>
                      <p className="m-0 required text-right">0</p>
                    </Col>
                  </Row>
                )}
              </div>

              <div className="modal-content__left-payment-detail">
                <Row>
                  <Col span={24} md={6}></Col>
                  {!currentBranch?.is_enable_v2 && (
                    <Col span={24} md={18}>
                      <div className="modal-content__left-payment-detail__item">
                        <span>Cần thanh toán</span>
                        <span>{formatCurrency(bookingLine.total_price)}</span>
                      </div>
                      <div className="modal-content__left-payment-detail__item">
                        <span>Đã thanh toán</span>
                        <span>{formatCurrency(bookingLine.paid_amount || 0)}</span>
                      </div>
                      <div className="modal-content__left-payment-detail__item">
                        <span>Còn lại</span>
                        <span>
                          {formatCurrency(bookingLine.total_price - bookingLine.paid_amount)}
                        </span>
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
            </>
          )}

          {bookingLine.status === BookingStatus.CANCEL && (
            <div className="modal-content__left-room-fee">
              <Row>
                <Col span={24} md={6}>
                  <span>Phí hủy phòng</span>
                </Col>
                <Col span={24} md={14}>
                  <p className="m-0">{bookingLine.cancel_reason}</p>
                </Col>
                <Col span={24} md={4}>
                  <p className="m-0 text-right">{formatCurrency(bookingLine.cancel_price)}</p>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </Col>
      <Col span={24} md={10}>
        <div className="modal-content__right">
          {/*<div className="modal-content__right-action">*/}
          {/*  <Button className="ant-btn-secondary">Trả phòng</Button>*/}
          {/*</div>*/}
          {isConfirmOrCheckIn && bookingLine.sale_order_name ? (
            <div className="modal-content__right-form">
              <Form
                form={form}
                name="payment-form"
                layout="vertical"
                style={{ width: '100%' }}
                initialValues={{
                  note: '',
                  payment_type: PAYMENT_TYPE.INCOME,
                  payment_method: PAYMENT_METHOD.CASH,
                  income_type: PAYMENT_FOR.ROOM
                }}
                onFinish={onFinishPayment}
                autoComplete="off"
              >
                <Form.Item name="payment_type">
                  <Radio.Group>
                    <Radio value={PAYMENT_TYPE.INCOME}>Thanh toán</Radio>
                    <Radio
                      disabled={bookingLine.total_price - bookingLine.paid_amount >= 0}
                      value={PAYMENT_TYPE.EXPENSE}
                    >
                      Hoàn tiền
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Row gutter={[8, 8]}>
                  <Col span={24} md={9}>
                    <Form.Item label="Hình thức TT" name="payment_method">
                      <Select options={updatedPaymentMethodOptions} />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={8}>
                    <Form.Item label="Thanh toán cho" name="income_type">
                      <Select options={PAYMENT_FOR_OPTIONS} />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={7}>
                    <Form.Item label="Số tiền" name="amount">
                      <InputNumber
                        readOnly={payment_type === PAYMENT_TYPE.EXPENSE}
                        formatter={value => formatInputNumber(value)}
                        required
                        min={0}
                        className="w-full"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item name="note">
                  <Input.TextArea placeholder="Ghi chú" />
                </Form.Item>

                <div className="text-right">
                  <Button
                    disabled={bookingLine.total_price - bookingLine.paid_amount === 0}
                    loading={isPendingMakePayment}
                    htmlType="submit"
                    className="ant-btn-secondary"
                  >
                    {payment_type === PAYMENT_TYPE.EXPENSE ? 'Hoàn tiền' : 'Thanh toán'}
                  </Button>
                </div>
              </Form>
            </div>
          ) : (
            currentBranch?.is_enable_v2 && (
              <div
                className="modal-content__redirect-to-cashier"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%'
                }}
              >
                <Button onClick={() => handleRedirectToCashier()}>Thu ngân</Button>
              </div>
            )
          )}
          {bookingLine.sale_order_name && (
            <div
              className="modal-content__right-payment-history"
              style={{ padding: isCancelOrCheckOut ? 0 : '' }}
            >
              <div className="modal-content__right-payment-history__item">
                <p className="m-0 payment-history-title">Đã thanh toán</p>
                <div className="payment-history-content-container">
                  {data?.map(item => (
                    <div
                      key={item.payment_id}
                      className={classNames('payment-history-content', {
                        'payment-history-content--cancel': item.state === PAYMENT_STATUS.CANCELLED
                      })}
                    >
                      <div>
                        <p
                          className={classNames(
                            'm-0 gap-2 flex items-center justify-between payment-history-content__title',
                            {
                              'line-through': item.state === PAYMENT_STATUS.CANCELLED
                            }
                          )}
                        >
                          <span>
                            {item.room_type_name} ({PAYMENT_METHOD_NAMES[item.payment_method]}) (
                            {PAYMENT_FOR_NAMES[item.income_type]})
                          </span>
                          {item.payment_method === PAYMENT_METHOD.DEBT &&
                            item.state === PAYMENT_STATUS.DRAFT && (
                              <Flex
                                align="center"
                                className="flex-1 justify-center"
                                gap="small"
                                vertical
                              >
                                <Button
                                  style={{
                                    left: '15%'
                                  }}
                                  type="default"
                                  size="small"
                                  onClick={() =>
                                    handleViewChargeDebtPayment(
                                      item.payment_id,
                                      item.amount,
                                      item.state
                                    )
                                  }
                                >
                                  Trả công nợ
                                </Button>
                              </Flex>
                            )}
                          <span>
                            {item.payment_method !== PAYMENT_METHOD.DEBT && (
                              <Button
                                icon={<PrinterOutlined />}
                                type="text"
                                onClick={() => goToInvoice(item.invoice_id)}
                              />
                            )}
                          </span>
                        </p>

                        <p
                          className={classNames('m-0 payment-history-content__time', {
                            'line-through': item.state === PAYMENT_STATUS.CANCELLED
                          })}
                        >
                          {toLocalTime(item.payment_date, 'HH:mm - DD/MM/YYYY')} - {item.username}
                        </p>
                        {item.note && (
                          <div className="note-payment layout-row flex">{item.note}</div>
                        )}
                        {item.cancel_reason && (
                          <p
                            className="required m-0"
                            style={{ marginTop: '4px', fontSize: '12px' }}
                          >
                            Hủy: {item.cancel_reason} - {item.canceled_by}
                          </p>
                        )}
                      </div>

                      <p
                        className={classNames('payment-history-number ', {
                          refund: item.payment_type === PAYMENT_TYPE.EXPENSE,
                          'line-through': item.state === PAYMENT_STATUS.CANCELLED
                        })}
                      >
                        {item.payment_type === PAYMENT_TYPE.INCOME ? '+' : '-'}{' '}
                        {formatCurrency(item.amount)}
                      </p>

                      <Button
                        className="payment-remove"
                        type="link"
                        icon={<CloseCircleFilled style={{ fontSize: 20, color: '#d9390d' }} />}
                        onClick={() => handleRemovePayment(item.payment_id)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default PaymentTab;
