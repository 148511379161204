import _ from 'lodash';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { TransactionPayment } from 'services/api/type/booking.type';
import { toLocalTime } from 'utils';

const borderColor = '#000';
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderBottomColor: borderColor,
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 28
  },
  date: {
    width: '15%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  total: {
    width: '89%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 4px'
  },
  price: {
    width: '20%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  qty: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  serviceFee: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  tax: {
    width: '13%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  amount: {
    width: '15%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 4px',
    alignItems: 'flex-end'
  },
  totalGroup: {
    width: '60%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 4px'
  },
  textBold: { fontSize: 11, fontFamily: 'Inter', fontWeight: 600 }
});

interface Props {
  remainAmount: number;
  listTax: {
    [key: number]: boolean;
  };
  forGroup?: boolean;
  transactionPayment?: TransactionPayment[];
  paidAmount?: number;
  totalPrice?: string;
}

const InvoiceTableFooter = ({
  remainAmount,
  listTax,
  forGroup,
  transactionPayment,
  paidAmount,
  totalPrice
}: Props) => {
  return (
    <>
      <View style={styles.row}>
        {forGroup ? (
          <>
            <View style={styles.totalGroup}>
              <Text style={styles.textBold}>Tổng (Total Amount)</Text>
            </View>
            <View style={styles.qty}>
              <Text style={styles.textBold}></Text>
            </View>
            <View style={styles.qty}>
              <Text style={styles.textBold}></Text>
            </View>
            <View style={styles.qty}>
              <Text style={styles.textBold}></Text>
            </View>
            <View style={styles.price}>
              <Text style={styles.textBold}></Text>
            </View>
            <View style={styles.price}>
              <Text style={styles.textBold}>{remainAmount?.toLocaleString('vn')}</Text>
            </View>
          </>
        ) : (
          <>
            <View style={styles.date}></View>
            <View style={styles.total}>
              <Text style={styles.textBold}>Tổng cộng tiền thanh toán (Total Amount)</Text>
            </View>
            <View style={{ ...styles.amount, width: _.isEmpty(listTax) ? '30%' : '15%' }}>
              <Text style={styles.textBold}>{totalPrice}</Text>
            </View>
          </>
        )}
      </View>

      {transactionPayment &&
        transactionPayment.length > 0 &&
        transactionPayment.map((payment, index) => (
          <View style={styles.row} key={index}>
            <View style={styles.date}>
              <Text>{toLocalTime(payment.transaction_date, 'DD/MM/YYYY')}</Text>
            </View>
            <View style={styles.total}>
              <Text>Thanh toán qua {payment.transaction_code_name}</Text>
            </View>
            <View style={{ ...styles.amount, width: _.isEmpty(listTax) ? '30%' : '15%' }}>
              <Text>{payment.amount?.toLocaleString('vn')}</Text>
            </View>
          </View>
        ))}
      <View style={styles.row}>
        <View style={styles.date}></View>
        <View style={styles.total}>
          <Text style={styles.textBold}>Tổng tiền đã thanh toán (Total Payment)</Text>
        </View>
        <View style={{ ...styles.amount, width: _.isEmpty(listTax) ? '30%' : '15%' }}>
          <Text style={styles.textBold}>{paidAmount?.toLocaleString('vn')}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.date}></View>
        <View style={styles.total}>
          <Text style={styles.textBold}>Số tiền còn lại (Balance)</Text>
        </View>
        <View style={{ ...styles.amount, width: _.isEmpty(listTax) ? '30%' : '15%' }}>
          <Text style={styles.textBold}>{remainAmount?.toLocaleString('vn')}</Text>
        </View>
      </View>
    </>
  );
};

export default InvoiceTableFooter;
