import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { Form, Modal, notification } from 'antd';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { undoCancelledTransactions } from 'services/api/module/booking.api';
import useModal from 'stores/useModal';
import QUERY_KEYS from 'services/api/queryKeys';
import { queryClient } from 'index';

function UndoCancelledTransactions() {
  const [form] = Form.useForm();

  const {
    undoCancelledTransactions: { cancelled_transaction_ids, isOpenUndoCancelledTransactions },
    closeUndoCancelledTransactions
  } = useModal();
  const { mutateAsync: mutateUndoCancelledTransactions, isPending } = useMutation({
    mutationFn: ({ cancelled_transaction_ids }: { cancelled_transaction_ids: number[] }) =>
      undoCancelledTransactions({ cancelled_transaction_ids })
  });

  const handleCloseUndoCancelledTransactions = () => {
    closeUndoCancelledTransactions();
    form.resetFields();
  };

  const handleUndoCancelledTransactions = async () => {
    try {
      await mutateUndoCancelledTransactions({
        cancelled_transaction_ids: cancelled_transaction_ids
      });
      closeUndoCancelledTransactions();
      form.resetFields();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRANSACTION_HISTORY_LOGS]
      });
      notification.success({
        message: 'Hoàn tác hủy giao dịch thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <Modal
      title="Xác nhận hoàn tác hủy giao dịch"
      centered
      width={410}
      open={isOpenUndoCancelledTransactions}
      onCancel={handleCloseUndoCancelledTransactions}
      closeIcon={<IconClose />}
      okText="Hoàn tác"
      cancelText="Đóng"
      onOk={handleUndoCancelledTransactions}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      className="modal-cancel-transactions"
      destroyOnClose
      confirmLoading={isPending}
    ></Modal>
  );
}

export default UndoCancelledTransactions;
