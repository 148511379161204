import React, { useEffect, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellValueChangedEvent, RowSelectedEvent } from 'ag-grid-community';
import { notification } from 'antd';
import { getCashierTransactionCols } from 'constants/ag-grid-table';
import { useGetGuestOrderDetail } from 'hooks/useGetGuestTransactions';
import { formatCurrency } from 'utils';
import { useMutation } from '@tanstack/react-query';
import { editTransactionNote } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import useCashierStore from 'stores/useCashier';
import useModal from 'stores/useModal';

function TransactionTable({
  orderId,
  setTransactionIds,
  setCancelledTransactionIds,
  isInvoiced,
  correctOrderId,
  hideDeleted,
  onCancelledCountChange
}: {
  orderId: number;
  setTransactionIds: (selectedKey: any) => void;
  isInvoiced: boolean;
  correctOrderId: number;
  hideDeleted: boolean;
  onCancelledCountChange: (cancelled: number) => void;
  setCancelledTransactionIds: (selectedKey: any) => void;
}) {
  const { data: orderDetail } = useGetGuestOrderDetail(orderId);
  const { isOpenViewPrintInvoiceV2 } = useModal();

  const { setSelectedTransactionId } = useCashierStore();

  const { mutateAsync: mutateEditTransactionNode } = useMutation({
    mutationFn: (params: any) => editTransactionNote(params)
  });

  useEffect(() => {
    if (isOpenViewPrintInvoiceV2 && orderDetail && correctOrderId) {
      setSelectedTransactionId(correctOrderId);
    } else {
      setSelectedTransactionId(undefined);
    }
    // eslint-disable-next-line
  }, [isOpenViewPrintInvoiceV2, orderDetail]);

  const handleChangeSelectedRow = (event: RowSelectedEvent) => {
    const transactionIds = event.api
      .getSelectedNodes()
      .filter(item => !item.data.is_deleted)
      .map(item => item.data.id);
    setTransactionIds(transactionIds);

    const cancelledTransactionIds = event.api
      .getSelectedNodes()
      .filter(item => item.data.is_deleted)
      .map(item => item.data.id);
    setCancelledTransactionIds(cancelledTransactionIds);
  };

  const handleChangeCellValue = async (event: CellValueChangedEvent) => {
    if (event.colDef.field === 'note') {
      const updatedNotice = event.newValue;
      const transactionId = event.data.id;
      try {
        await mutateEditTransactionNode({ transaction_id: transactionId, note: updatedNotice });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
        });
        notification.success({
          message: 'Thay đổi ghi chú thành công'
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Có lỗi xảy ra'
        });
      }
    }
  };

  const cashierTransactionCols = useMemo(() => {
    return getCashierTransactionCols();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (orderDetail?.transactions) {
      const cancelledCount = orderDetail.transactions.filter(item => item.is_deleted).length;
      onCancelledCountChange(cancelledCount);
    }
  }, [orderDetail, onCancelledCountChange]);

  const filteredTransactions = useMemo(() => {
    if (!orderDetail?.transactions) return [];
    return hideDeleted
      ? orderDetail.transactions.filter(t => !t.is_deleted)
      : orderDetail.transactions;
  }, [orderDetail?.transactions, hideDeleted]);

  return (
    <div>
      <div
        className="pmn-cashier__transaction-table ag-theme-quartz"
        style={{ height: '500px', width: '100%' }}
      >
        <AgGridReact
          rowData={filteredTransactions}
          columnDefs={cashierTransactionCols}
          selection={{
            mode: 'multiRow',
            checkboxes: !isInvoiced,
            headerCheckbox: !isInvoiced
          }}
          onCellValueChanged={handleChangeCellValue}
          onRowSelected={handleChangeSelectedRow}
          stopEditingWhenCellsLoseFocus
        />
      </div>

      <div style={{ marginTop: 12 }}>
        <h5 className="m-0" style={{ fontWeight: 600 }}>
          Balance: <span className="text-error">{formatCurrency(orderDetail?.balance || 0)}</span>
        </h5>
      </div>
    </div>
  );
}

export default TransactionTable;
