import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Dropdown, Spin, Tooltip, notification } from 'antd';
import {
  CheckCircleOutlined,
  CarryOutOutlined,
  WarningOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import classNames from 'classnames';

import useModal from 'stores/useModal';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { DISPLAY_ROOM_VALUE, FILTER_STATUS_ROOM_MAP_VALUE } from 'constants/common';
import { formatCurrency, toLocalTime } from 'utils';
import { ACTION_ROOM_ITEM, ACTION_ROOM_ITEM_EMPTY } from 'constants/table';

import useBookingStore from 'stores/useBooking';

import { cleanRoom } from 'services/api/module/room.api';
import { actionCheckIn } from 'services/api/module/booking.api';

import { ReactComponent as IconDirty } from 'assets/images/ic_house_keeping_dirty_no_fill.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import { ReactComponent as IconGroupUser } from 'assets/images/group-user.svg';
import { ReactComponent as IconFix } from 'assets/images/fix.svg';
import IconArrival from 'assets/images/ic_arrival.svg';
import IconDeparture from 'assets/images/ic_departure.svg';
import IconNote from 'assets/images/ic_note_room.svg';
import IconUser from 'assets/images/ic_note_adults.svg';
import IconChildren from 'assets/images/ic_note_children.svg';
import { RoomLock } from 'services/api/type/room.type';
import { usePermissions } from 'AuthorizePermission';

import { useFilterActions } from 'helper/common/permission.action.helper';
import { ACTION_BOOKING } from 'components/booking-list/action-booking';

interface RoomItemProps {
  id: string;
  type: string;
  status: string;
  is_dirty: boolean;
  roomId: number;
  bookingLineId: number;
  is_group: boolean;
  displayRoom: string;
  room_lock?: RoomLock;
  bookingId: number;
  onViewDetail: (bookingLineId: number) => void;
  info?: {
    customerName: string;
    rangeDate: string;
    fit: string;
    price: number;
    checkInTime: string;
    checkOutTime: string;
    note: string;
    adult: number;
    child: number;
  };
}
const MAP_ICON: any = {
  [FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE]: CheckCircleOutlined,
  [FILTER_STATUS_ROOM_MAP_VALUE.BOOKED]: CarryOutOutlined,
  [FILTER_STATUS_ROOM_MAP_VALUE.NO_SHOW]: WarningOutlined,
  [FILTER_STATUS_ROOM_MAP_VALUE.CHECKIN]: IconBedUser,
  [FILTER_STATUS_ROOM_MAP_VALUE.NOT_CHECKOUT]: UserSwitchOutlined,
  [FILTER_STATUS_ROOM_MAP_VALUE.LOCKED]: IconFix
};

const MAP_BG_COLOR: any = {
  [FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE]: '#449F46',
  [FILTER_STATUS_ROOM_MAP_VALUE.BOOKED]: '#0782EB',
  [FILTER_STATUS_ROOM_MAP_VALUE.NO_SHOW]: '#9513A8',
  [FILTER_STATUS_ROOM_MAP_VALUE.NOT_CHECKOUT]: '#E8781B',
  [FILTER_STATUS_ROOM_MAP_VALUE.CHECKIN]: '#CE370F',
  [FILTER_STATUS_ROOM_MAP_VALUE.LOCKED]: '#DCDCDC'
};

function RoomItem({
  id,
  type,
  status,
  info,
  is_dirty,
  roomId,
  bookingLineId,
  is_group,
  displayRoom,
  room_lock,
  bookingId,
  onViewDetail
}: RoomItemProps) {
  const Icon = MAP_ICON[status];
  const {
    setIsOpenCancelRoom,
    setInfoConfirmModal,
    setIsOpenAddService,
    setIsOpenChangeRoom,
    setIsOpenChangeDate,
    setIsOpenCancelAssignRoom,
    setIsOpenFixRoom
  } = useModal();
  const { setBookingLineId, setRoom } = useBookingStore();
  const { permissions, hasPermission } = usePermissions();

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [isOpenTooltip, setIsOpenTooltip] = useState(false);

  const { mutateAsync: mutateDirtyRoom, isPending: isPendingDirty } = useMutation({
    mutationFn: ({ ids, is_clean }: { ids: number[]; is_clean: boolean }) => {
      return cleanRoom(ids, is_clean);
    }
  });

  const { mutateAsync: mutateCheckIn, isPending: isPendingCheckIn } = useMutation({
    mutationFn: () => actionCheckIn(bookingLineId)
  });

  const handleClickMenu = async (menu: any) => {
    switch (menu.key) {
      case ACTION_BOOKING.DIRTY_ROOM:
        setInfoConfirmModal(true, {
          title: 'Xác nhận phòng bẩn',
          onOk: () => {
            setInfoConfirmModal(false);
            handleMakeDirtyOrClean(roomId);
          }
        });
        break;
      case ACTION_BOOKING.CLEAN_ROOM:
        setInfoConfirmModal(true, {
          title: 'Xác nhận phòng sạch',
          onOk: () => {
            setInfoConfirmModal(false);
            handleMakeDirtyOrClean(roomId, true);
          }
        });
        break;
      case ACTION_BOOKING.DETAIL:
        onViewDetail(bookingLineId);
        break;
      case ACTION_BOOKING.ADD_SERVICE:
        setBookingLineId(Number(bookingLineId));
        setIsOpenAddService(true);
        break;
      case ACTION_BOOKING.CANCEL_BOOKING:
        setBookingLineId(bookingLineId);
        setIsOpenCancelRoom(true);
        break;
      case ACTION_BOOKING.CHANGE_ROOM: {
        setBookingLineId(Number(bookingLineId));
        setIsOpenChangeRoom(true);
        break;
      }
      case ACTION_BOOKING.CHANGE_DATE: {
        setBookingLineId(Number(bookingLineId));
        setIsOpenChangeDate(true);
        break;
      }
      case ACTION_BOOKING.GET_ROOM: {
        setInfoConfirmModal(true, {
          title: 'Xác nhận nhận phòng',
          onOk: () => {
            setInfoConfirmModal(false);
            handleCheckIn();
          }
        });
        break;
      }
      case ACTION_BOOKING.CANCEL_ASSIGN_ROOM: {
        setBookingLineId(Number(bookingLineId));
        setIsOpenCancelAssignRoom(true);
        break;
      }
      case ACTION_BOOKING.FIX_ROOM: {
        setRoom(roomId, id, room_lock);
        setIsOpenFixRoom(true);
        break;
      }
      default:
        break;
    }
  };

  const handleMakeDirtyOrClean = async (roomId: number, isClean: boolean = false) => {
    await mutateDirtyRoom({
      ids: [roomId],
      is_clean: isClean
    });

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_STATUS]
    });

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLineId]
    });
  };

  const handleCheckIn = async () => {
    try {
      await mutateCheckIn();
      notification.success({
        message: 'Nhận phòng thành công'
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_STATUS]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, bookingLineId]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Lỗi xảy ra'
      });
    }
  };

  const handleShowFixRoom = () => {
    if (status !== FILTER_STATUS_ROOM_MAP_VALUE.LOCKED) {
      return;
    }
    setRoom(roomId, id, room_lock);
    setIsOpenFixRoom(true);
  };

  const handleChangeOpenDropdown = (value: boolean) => {
    setIsOpenDropdown(value);
    if (value) {
      setIsOpenTooltip(false);
    }
  };

  const handleChangeOpenTooltip = (value: boolean) => {
    if (!isOpenDropdown) {
      setIsOpenTooltip(value);
    }
  };

  const menuActionsEmpty = useMemo(() => {
    return (
      ACTION_ROOM_ITEM_EMPTY?.filter(item =>
        is_dirty ? item?.key !== ACTION_BOOKING.DIRTY_ROOM : item?.key !== ACTION_BOOKING.CLEAN_ROOM
      ) || []
    );
  }, [is_dirty]);

  const filteredActions = useFilterActions(ACTION_ROOM_ITEM, permissions, hasPermission);

  const menuActions = useMemo(() => {
    return (
      filteredActions
        ?.filter(item =>
          is_dirty
            ? item?.key !== ACTION_BOOKING.DIRTY_ROOM
            : item?.key !== ACTION_BOOKING.CLEAN_ROOM
        )
        .filter(item =>
          status === FILTER_STATUS_ROOM_MAP_VALUE.CHECKIN ||
          status === FILTER_STATUS_ROOM_MAP_VALUE.NOT_CHECKOUT
            ? item?.key !== ACTION_BOOKING.CANCEL_BOOKING &&
              item?.key !== `${ACTION_BOOKING.CANCEL_BOOKING}-divider` &&
              item?.key !== ACTION_BOOKING.GET_ROOM &&
              item?.key !== ACTION_BOOKING.CANCEL_ASSIGN_ROOM
            : true
        ) || []
    );
  }, [filteredActions, is_dirty, status]);

  const renderTooltip = () => {
    if (status === FILTER_STATUS_ROOM_MAP_VALUE.AVAILABLE) {
      return '';
    }
    if (status === FILTER_STATUS_ROOM_MAP_VALUE.LOCKED) {
      return (
        <div className="pms-room-item__tooltip">
          <div className="pms-room-item__tooltip-header">
            <div className="pms-room-item__tooltip-header__customer">
              <span>Sửa phòng</span>
            </div>
            <div className="pms-room-item__tooltip-header__timeline">
              <IconFix />
              {room_lock?.start_date
                ? toLocalTime(room_lock?.start_date, 'DD/MM HH:mm')
                : ''} - {room_lock?.end_date ? toLocalTime(room_lock?.end_date, 'DD/MM HH:mm') : ''}
            </div>
          </div>

          <div className="pms-room-item__tooltip-body" style={{ borderBottom: 0 }}>
            <img src={IconNote} alt="note" />
            <span>{room_lock?.reason}</span>
          </div>
        </div>
      );
    }
    return (
      <div className="pms-room-item__tooltip">
        <div className="pms-room-item__tooltip-header">
          <div className="pms-room-item__tooltip-header__customer">
            <span>{info?.customerName}</span>
          </div>
          <div className="pms-room-item__tooltip-header__timeline">
            {info?.checkInTime && (
              <div className="flex items-center" style={{ gap: '4px' }}>
                <img src={IconArrival} alt="arrival" />
                <span>{toLocalTime(info?.checkInTime, 'DD/MM HH:mm')}</span>
              </div>
            )}
            {info?.checkOutTime && (
              <div className="flex items-center" style={{ gap: '4px' }}>
                <img src={IconDeparture} alt="departure" />
                <span>{toLocalTime(info?.checkOutTime, 'DD/MM HH:mm')}</span>
              </div>
            )}
          </div>
        </div>
        {info?.note && (
          <div className="pms-room-item__tooltip-body">
            <img src={IconNote} alt="note" />
            <span>{info?.note}</span>
          </div>
        )}
        <div className="pms-room-item__tooltip-footer">
          <div className="pms-room-item__tooltip-footer__number-customer">
            <div className="flex items-center" style={{ gap: '2px' }}>
              <img src={IconUser} alt="adults" />
              <span>{info?.adult}</span>
            </div>
            <div className="flex items-center" style={{ gap: '2px' }}>
              <img src={IconChildren} alt="children" />
              <span>{info?.child}</span>
            </div>
          </div>
          <div className="pms-room-item__tooltip-footer__booking-id">
            <span>#{bookingId}</span>
          </div>
        </div>
      </div>
    );
  };

  const isPending = isPendingCheckIn || isPendingDirty;

  const renderRoomItemDetail = () => {
    return (
      <div
        className={classNames('pms-room-item', {
          'pms-room-item--fixing': status === FILTER_STATUS_ROOM_MAP_VALUE.LOCKED
        })}
        onClick={handleShowFixRoom}
      >
        {isPending && (
          <div className="overlay">
            <Spin />
          </div>
        )}
        <div className="pms-room-item__id" style={{ backgroundColor: MAP_BG_COLOR[status] }}>
          <span className="type">{type}</span>
          <span className="id">{id}</span>
          <span className="flex items-center icon-wrapper" style={{ gap: '4px' }}>
            {is_group && <IconGroupUser />}
            <Icon />
            {is_dirty && <IconDirty className="text-black" />}
          </span>
        </div>

        <div
          className={classNames('pms-room-item__info', {
            empty: _.isEmpty(info) && status !== FILTER_STATUS_ROOM_MAP_VALUE.LOCKED,
            locked: status === FILTER_STATUS_ROOM_MAP_VALUE.LOCKED
          })}
        >
          {info ? (
            <>
              <div className="pms-room-item__info-inner">
                <span className="customer-name">{info.customerName}</span>
                <span className="range-date">{info.rangeDate}</span>
                <span className="fit">{info.fit}</span>
                <div className="flex items-center justify-between">
                  {info.price !== undefined && (
                    <span className="price truncate" title={formatCurrency(info.price, 'VND')}>
                      {formatCurrency(info.price, 'VND')}
                    </span>
                  )}
                  <div className="pms-room-item__info__number-customer">
                    <div className="flex items-center" style={{ gap: '2px' }}>
                      <img src={IconUser} alt="adults" />
                      <span>{info?.adult}</span>
                    </div>
                    <div className="flex items-center" style={{ gap: '2px' }}>
                      <img src={IconChildren} alt="children" />
                      <span>{info?.child}</span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : status === FILTER_STATUS_ROOM_MAP_VALUE.LOCKED ? (
            <div className="pms-room-item__info-inner">
              <span className="customer-name">Phòng sửa</span>
              <span className="range-date">
                {room_lock?.start_date ? toLocalTime(room_lock?.start_date, 'DD/MM HH:mm') : ''} -{' '}
                {room_lock?.end_date ? toLocalTime(room_lock?.end_date, 'DD/MM HH:mm') : ''}
              </span>
              <span className="fit">{room_lock?.reason}</span>
            </div>
          ) : (
            <p className="empty">Phòng trống</p>
          )}
        </div>
      </div>
    );
  };

  const renderRoomItemSimple = () => {
    return (
      <div className="pms-room-item pms-room-item--simple" onClick={handleShowFixRoom}>
        {isPending && (
          <div className="overlay">
            <Spin />
          </div>
        )}
        <div
          className={classNames('pms-room-item__id', {
            'pms-room-item__id--fixing': status === FILTER_STATUS_ROOM_MAP_VALUE.LOCKED
          })}
        >
          <span className="dot" style={{ backgroundColor: MAP_BG_COLOR[status] }}></span>
          <span className="type">{type}</span>
          <span className="id">{id}</span>
          <span className="flex items-center icon-wrapper" style={{ gap: '4px' }}>
            {is_group && <IconGroupUser />}
            <Icon />
            {is_dirty && <IconDirty className="text-black" />}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: _.isEmpty(info) ? menuActionsEmpty : menuActions,
        onClick: handleClickMenu
      }}
      placement="bottom"
      overlayClassName="action-booking-list"
      destroyPopupOnHide
      disabled={status === FILTER_STATUS_ROOM_MAP_VALUE.LOCKED}
      onOpenChange={handleChangeOpenDropdown}
    >
      <Tooltip
        open={isOpenTooltip}
        onOpenChange={handleChangeOpenTooltip}
        overlayClassName="pms-room-item__tooltip-overlay"
        title={renderTooltip()}
        arrow={false}
      >
        {displayRoom === DISPLAY_ROOM_VALUE.DETAIL
          ? renderRoomItemDetail()
          : renderRoomItemSimple()}
      </Tooltip>
    </Dropdown>
  );
}

export default RoomItem;
