import React, { useEffect, useState } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { Button, DatePicker, Form, FormProps, Input, Modal, Radio } from 'antd';

import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import useCommonStore from 'stores/useCommon';
import { useGetMe } from 'hooks/useGetMe';
import { useMutation } from '@tanstack/react-query';
import { updateInvoiceDetails } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import useCashierStore from 'stores/useCashier';
import {
  useGetGuestOrderDetail,
  useGetTransactionsFromOrders
} from 'hooks/useGetGuestTransactions';
import BillPDFv2 from 'components/bill-pdf/v2';
import { useGetGroupBookingDetail } from 'hooks/useGetGroupBookings';

const today = dayjs();

export enum CHARGE_TYPE {
  ALL = 'all',
  ROOM = 'room',
  SERVICE = 'service'
}

function PrintInvoiceModalV2() {
  const [form] = Form.useForm();
  const {
    isOpenViewPrintInvoiceV2,
    setIsOpenViewPrintInvoiceV2,
    setIsOpenSignature,
    isOpenViewGroupInvoice,
    setIsOpenViewGroupInvoice,
    group_id
  } = useModal();

  const { data: dataGroup } = useGetGroupBookingDetail(Number(group_id));

  const { bookingLineDetail } = useBookingStore();
  const { currentBranch } = useCommonStore();
  const { selectedTransactionId } = useCashierStore();

  const { data: orderDetail } = useGetGuestOrderDetail(selectedTransactionId);

  const { data: transactionPayment } = useGetTransactionsFromOrders(
    bookingLineDetail?.booking_line_guest_ids?.join(','),
    orderDetail?.id
  );
  const { data: me } = useGetMe();

  const [dataChange, setDataChange] = useState<any>({});

  const { mutateAsync: UpdateInvoiceDetails } = useMutation({
    mutationFn: async (values: any) => {
      const id = bookingLineDetail?.booking_line_id;
      if (id === undefined) {
        return;
      }
      const data = {
        partner_name: values.partnerName || '',
        company_name: values.company || '',
        company_address: values.companyAddress || '',
        tax_code: values.taxCode || ''
      };

      const bookingDetail = await updateInvoiceDetails(id, data);
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, id]
      });
      return bookingDetail;
    }
  });

  const [signatureStaff, setSignatureStaff] = useState('');
  const [signatureGuest, setSignatureGuest] = useState('');

  useEffect(() => {
    if (isOpenViewPrintInvoiceV2 && bookingLineDetail) {
      const initData = {
        chargeType: CHARGE_TYPE.ALL,
        createdDate: today,
        saleOrder: orderDetail ? orderDetail.sale_order_name : bookingLineDetail?.sale_order_name,
        partnerName: bookingLineDetail.invoice_details?.partner_name,
        company: bookingLineDetail.invoice_details?.company_name,
        companyAddress: bookingLineDetail.invoice_details?.company_address,
        taxCode: bookingLineDetail.invoice_details?.tax_code
      };
      form.setFieldsValue(initData);
      setDataChange(initData);
      setSignatureStaff('');
      setSignatureGuest('');
    }
  }, [form, bookingLineDetail, isOpenViewPrintInvoiceV2, orderDetail]);

  const handleCancel = () => {
    setIsOpenViewPrintInvoiceV2(false);
    setIsOpenViewGroupInvoice(false);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    setDataChange(values);
    await UpdateInvoiceDetails(values);
  };

  const handleOpenSignatureStaff = () => {
    setIsOpenSignature(true, {
      title: 'Chữ ký nhân viên',
      onOk: (data: any) => {
        setSignatureStaff(data);
      }
    });
  };

  const handleOpenSignatureGuest = () => {
    setIsOpenSignature(true, {
      title: 'Chữ ký của khách',
      onOk: (data: any) => {
        setSignatureGuest(data);
      }
    });
  };

  return (
    <Modal
      title="Xem trước khi in"
      className="modal-view-bill-pdf"
      centered
      width={1200}
      open={isOpenViewPrintInvoiceV2 || isOpenViewGroupInvoice}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <PDFViewer width="1150" height="650" className="bill-pdf-file">
        <BillPDFv2
          bookingLineDetail={bookingLineDetail}
          orderDetail={orderDetail}
          me={me}
          dataChange={dataChange}
          signatureStaff={signatureStaff}
          signatureGuest={signatureGuest}
          currentBranch={currentBranch}
          dataGroup={dataGroup}
          transactionPayment={transactionPayment}
        />
      </PDFViewer>
      <Form
        form={form}
        name="bill-pdf-form"
        layout="vertical"
        style={{ width: '250px' }}
        initialValues={{
          chargeType: CHARGE_TYPE.ALL,
          createdDate: today,
          saleOrder: orderDetail ? orderDetail.sale_order_name : bookingLineDetail?.sale_order_name,
          roomName: bookingLineDetail?.room_name,
          partnerName: bookingLineDetail?.invoice_details?.partner_name,
          company: bookingLineDetail?.invoice_details?.company_name,
          companyAddress: bookingLineDetail?.invoice_details?.company_address,
          taxCode: bookingLineDetail?.invoice_details?.tax_code
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="chargeType" label="Thanh toán cho">
          <Radio.Group>
            <Radio value={CHARGE_TYPE.ALL}>Tất cả</Radio>
            <Radio value={CHARGE_TYPE.ROOM}>Tiền phòng</Radio>
            <Radio value={CHARGE_TYPE.SERVICE}>Tiền dịch vụ</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="createdDate" label="Ngày tạo">
          <DatePicker format="DD/MM/YYYY" className="w-full" />
        </Form.Item>
        <Form.Item name="partnerName" label="Tên khách hàng">
          <Input />
        </Form.Item>
        <Form.Item name="company" label="Công ty">
          <Input />
        </Form.Item>
        <Form.Item name="companyAddress" label="Địa chỉ">
          <Input />
        </Form.Item>
        <Form.Item name="taxCode" label="Tax Code">
          <Input />
        </Form.Item>
        <Button htmlType="submit" className="ant-btn-secondary w-full">
          Lưu thay đổi
        </Button>
        <div className="flex" style={{ flexDirection: 'column', gap: '8px', marginTop: 16 }}>
          <Button onClick={handleOpenSignatureStaff}>Chữ ký nhân viên</Button>
          <Button onClick={handleOpenSignatureGuest}>Chữ ký của khách</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default PrintInvoiceModalV2;
