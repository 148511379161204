export interface TransactionHistoryLogs {
  booking_line_order_id: string;
  start_date: string;
  end_date: string;
  type?: string;
}

export const TransactionHistoryLogsType: { [key: string]: string } = {
  // room_charge_revenue: 'Room Charge Revenue',
  // service_breakfast_revenue: 'Breakfast Service Revenue',
  // service_revenue: 'Service Revenue',
  // payment: 'Payment',
  // refund: 'Refund',
  void_transaction: 'Void Transaction',
  move_transaction: 'Move Transaction',
  undo_voided_transaction: 'Undo voided Transaction',
  change_room_charge: 'Change Room Charge'
};
