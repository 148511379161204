import React, { useEffect, useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import useCommonStore from 'stores/useCommon';
import { formatCurrency, toLocalTime } from 'utils';
import { useGetDetailedAnalysisReportForExport } from 'hooks/useGetDetailedAnalysisReport';

interface Props {
  fileName: string;
  filterOptions: any;
}

const ExportDetailedAnalysisReport = ({ fileName, filterOptions }: Props) => {
  const { currentBranch } = useCommonStore();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const [enable, setEnable] = useState<boolean>(false);

  const { data: detailedAnalysisData } = useGetDetailedAnalysisReportForExport(
    filterOptions,
    enable
  );

  useEffect(() => {
    setEnable(!isLoadingExport);
  }, [isLoadingExport]);

  const totalRoomPrices = useMemo(
    () => detailedAnalysisData.reduce((cur, next) => cur + next.room_price * next.booking_days, 0),
    [detailedAnalysisData]
  );

  const totalServicePrices = useMemo(
    () => detailedAnalysisData.reduce((cur, next) => cur + next.service_price, 0),
    [detailedAnalysisData]
  );

  const totalDiscountTotal = useMemo(() => {
    return detailedAnalysisData.reduce((cur, next) => cur + next.discount_total, 0);
  }, [detailedAnalysisData]);

  const totalAmount = useMemo(
    () =>
      detailedAnalysisData.reduce(
        (cur, next) =>
          cur + next.room_price * next.booking_days + next.service_price - next.discount_total,
        0
      ),
    [detailedAnalysisData]
  );

  const totalBookingDays = useMemo(
    () => detailedAnalysisData.reduce((cur, next) => cur + next.booking_days, 0),
    [detailedAnalysisData]
  );

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = async () => {
    try {
      setIsLoadingExport(true);

      const dataToExport = detailedAnalysisData.map((item, index) => ({
        stt: String(index + 1),
        booking_id: String(item.booking_id),
        room_type_name: item.room_type_name || '',
        room_no: item.room_no || '',
        check_in: toLocalTime(item.check_in, 'DD-MM-YYYY HH:mm:ss') || '',
        check_out: toLocalTime(item.check_out, 'DD-MM-YYYY HH:mm:ss') || '',
        source: item.source || '',
        booking_days: item.booking_days || '',
        total_room_price: formatCurrency(item.room_price * item.booking_days),
        service_price: String(item.service_price),
        discount: formatCurrency(item.discount_total),
        total_amount: formatCurrency(
          item.room_price * item.booking_days + item.service_price - item.discount_total
        )
      }));

      dataToExport.push({
        stt: 'Tổng',
        booking_id: '',
        room_type_name: '',
        room_no: '',
        check_in: '',
        check_out: '',
        source: '',
        booking_days: totalBookingDays,
        total_room_price: formatCurrency(totalRoomPrices),
        service_price: formatCurrency(totalServicePrices),
        discount: totalDiscountTotal ? formatCurrency(totalDiscountTotal) : '0',
        total_amount: formatCurrency(totalAmount)
      });

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('Report');

      sheet.mergeCells('A1:L1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 16 };

      sheet.mergeCells('A2:L2');
      sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address}`;
      sheet.getCell('A2').font = { size: 12 };

      sheet.mergeCells('A3:L3');
      sheet.getCell('A3').value = currentBranch?.phone_contact
        ? `ĐT: ${currentBranch?.phone_contact}`
        : '';
      sheet.getCell('A3').font = { size: 12 };

      sheet.mergeCells('A4:L4');
      sheet.getCell('A4').value = 'Doanh thu chi tiết';
      sheet.getCell('A4').font = { bold: true, size: 16 };
      sheet.getCell('A4').alignment = { horizontal: 'center' };

      sheet.mergeCells('A5:L5');
      sheet.getCell('A5').value =
        `Từ ngày ${toLocalTime(filterOptions.check_in, 'DD-MM-YYYY')} - Đến ngày ${toLocalTime(filterOptions.check_out, 'DD-MM-YYYY')}`;
      sheet.getCell('A5').font = { bold: true, size: 12 };
      sheet.getCell('A5').alignment = { horizontal: 'center' };

      sheet.mergeCells('A6:L6');
      sheet.getCell('A7').value = 'STT';
      sheet.getCell('A7').font = { bold: true, size: 11 };
      sheet.getCell('A7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('B7').value = 'Mã đặt phòng';
      sheet.getCell('B7').font = { bold: true, size: 11 };
      sheet.getCell('B7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('C7').value = 'Loại phòng';
      sheet.getCell('C7').font = { bold: true, size: 11 };
      sheet.getCell('C7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('D7').value = 'Số phòng';
      sheet.getCell('D7').font = { bold: true, size: 11 };
      sheet.getCell('D7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('E7').value = 'Ngày đến';
      sheet.getCell('E7').font = { bold: true, size: 11 };
      sheet.getCell('E7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('F7').value = 'Ngày đi';
      sheet.getCell('F7').font = { bold: true, size: 11 };
      sheet.getCell('F7').alignment = { horizontal: 'center' };

      sheet.getCell('G7').value = 'Nguồn';
      sheet.getCell('G7').font = { bold: true, size: 11 };
      sheet.getCell('G7').alignment = { horizontal: 'center' };

      sheet.getCell('H7').value = 'Số đêm';
      sheet.getCell('H7').font = { bold: true, size: 11 };
      sheet.getCell('H7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('I7').value = 'Tổng tiền phòng';
      sheet.getCell('I7').font = { bold: true, size: 11 };
      sheet.getCell('I7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('J7').value = 'Dịch vụ';
      sheet.getCell('J7').font = { bold: true, size: 11 };
      sheet.getCell('J7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('K7').value = 'Chiết khấu (vnd)';
      sheet.getCell('K7').font = { bold: true, size: 11 };
      sheet.getCell('K7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      sheet.getCell('L7').value = 'Tổng doanh thu';
      sheet.getCell('L7').font = { bold: true, size: 11 };
      sheet.getCell('L7').alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };

      ['A7', 'B7', 'C7', 'D7', 'E7', 'F7', 'G7', 'H7', 'I7', 'J7', 'K7', 'L7'].forEach(key => {
        sheet.getCell(key).font = { bold: true, size: 11 };
        sheet.getCell(key).alignment = {
          horizontal: 'center',
          vertical: 'middle'
        };
        sheet.getCell(key).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' }
        };
      });

      sheet.columns = [
        { key: 'stt', width: 10, style: { alignment: { horizontal: 'center' } } },
        { key: 'booking_id', width: 15, style: { alignment: { horizontal: 'center' } } },
        { key: 'room_type_name', width: 12 },
        { key: 'room_no', width: 10, style: { alignment: { horizontal: 'center' } } },
        { key: 'check_in', width: 20 },
        { key: 'check_out', width: 20 },
        { key: 'source', width: 20 },
        { key: 'booking_days', width: 10, style: { alignment: { horizontal: 'center' } } },
        { key: 'total_room_price', width: 17, style: { alignment: { horizontal: 'center' } } },
        { key: 'service_price', width: 15, style: { alignment: { horizontal: 'center' } } },
        { key: 'discount', width: 25, style: { alignment: { horizontal: 'center' } } },
        { key: 'total_amount', width: 20, style: { alignment: { horizontal: 'center' } } }
      ];

      dataToExport.forEach((item, index) => {
        const row = sheet.addRow(item);
        row.eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          if (index === dataToExport.length - 1) {
            cell.font = { bold: true };
          }
        });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (error) {
      console.error('Export Error:', error);
    } finally {
      setIsLoadingExport(false);
    }
  };

  return (
    <Button icon={<DownloadOutlined />} onClick={exportToCSV} loading={isLoadingExport}>
      Xuất dữ liệu
    </Button>
  );
};

export default ExportDetailedAnalysisReport;
