import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { MenuProps, Dropdown } from 'antd';
import ReactDOMServer from 'react-dom/server';
import dayjs from 'dayjs';

import { toLocalTime } from 'utils';
import { ReactComponent as IconFix } from 'assets/images/fix.svg';
import IconArrival from 'assets/images/ic_arrival.svg';
import IconDeparture from 'assets/images/ic_departure.svg';
import IconUser from 'assets/images/ic_note_adults.svg';
import IconChildren from 'assets/images/ic_note_children.svg';
import IconNote from 'assets/images/ic_note_room.svg';
import { ReactComponent as IconExclamation } from 'assets/images/exclamation-circle.svg';
import { ReactComponent as IconCheckout } from 'assets/images/checkout.svg';
import { BookingLine, BookingStatus } from 'services/api/type/booking.type';
import { ACTION_ROOM_ITEM } from 'constants/table';
import Emitter, { EVENT_KEY } from 'utils/eventEmitter';
import { usePermissions } from 'AuthorizePermission';

import { useFilterActions } from 'helper/common/permission.action.helper';
import { ACTION_BOOKING } from 'components/booking-list/action-booking';

interface ItemProps {
  itemContext: any;
  itemProps: any;
  item: any;
  mousePositionX: any;
  mousePositionY: any;
  onClickMenu: (info: any) => void;
}

function TimelineItem({
  item,
  itemProps,
  itemContext,
  mousePositionX,
  mousePositionY,
  onClickMenu
}: ItemProps) {
  const boxRef = useRef<any>();
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [changePositionX, setChangePositionX] = useState(mousePositionX);
  const [changePositionY, setChangePositionY] = useState(mousePositionY);

  const { permissions, hasPermission } = usePermissions();

  const filteredActions = useFilterActions(ACTION_ROOM_ITEM, permissions, hasPermission);

  useEffect(() => {
    const handleChangePosition = (params: any) => {
      if (!isOpenDropdown) {
        setChangePositionX(params.x);
        setChangePositionY(params.y);
      }
    };

    Emitter.on(EVENT_KEY.CHANGE_MOUSE_POSITION, handleChangePosition);
    return () => {
      Emitter.off(EVENT_KEY.CHANGE_MOUSE_POSITION, handleChangePosition);
    };
  }, [isOpenDropdown]);

  const getMenuTimelineItem = (item: any): MenuProps['items'] => {
    if (!item) {
      return [];
    }

    if (item.lock_id) {
      return [
        {
          label: (
            <div className="action-item">
              <IconFix />
              <span>Sửa phòng</span>
            </div>
          ),
          key: ACTION_BOOKING.FIX_ROOM
        }
      ];
    }

    switch (item.booking.status) {
      case BookingStatus.CHECK_OUT: {
        return [
          {
            key: ACTION_BOOKING.DETAIL,
            label: (
              <div className="action-item">
                <IconExclamation />
                <span>Chi tiết</span>
              </div>
            )
          }
        ];
      }
      case BookingStatus.CONFIRM: {
        return filteredActions.filter(
          item => item?.key !== ACTION_BOOKING.DIRTY_ROOM && item?.key !== ACTION_BOOKING.CLEAN_ROOM
        );
      }
      case BookingStatus.CHECK_IN:
        {
          const result = filteredActions.filter(
            item =>
              ![
                ACTION_BOOKING.GET_ROOM,
                ACTION_BOOKING.CANCEL_ASSIGN_ROOM,
                ACTION_BOOKING.CANCEL_BOOKING,
                ACTION_BOOKING.DIRTY_ROOM,
                ACTION_BOOKING.CLEAN_ROOM
              ].includes(String(item?.key))
          );
          result?.pop();
          result?.splice(1, 0, {
            key: ACTION_BOOKING.CHECK_OUT,
            label: (
              <div className="action-item">
                <IconCheckout style={{ opacity: 0.25 }} />
                <span>Trả phòng</span>
              </div>
            )
          });
          return result;
        }
        ``;
      default:
        break;
    }

    return [];
  };

  const renderTooltip = (item: any) => {
    if (item?.lock_id) {
      return (
        <div className="pms-room-item__tooltip">
          <div className="pms-room-item__tooltip-header">
            <div className="pms-room-item__tooltip-header__customer">
              <span>Sửa phòng</span>
            </div>
            <div className="pms-room-item__tooltip-header__timeline">
              <IconFix />
              {item?.start_time ? dayjs(item?.start_time).format('DD/MM HH:mm') : ''} -{' '}
              {item?.end_time ? dayjs(item?.end_time).format('DD/MM HH:mm') : ''}
            </div>
          </div>

          <div className="pms-room-item__tooltip-body" style={{ borderBottom: 0 }}>
            <img src={IconNote} alt="note" />
            <span>{item?.title}</span>
          </div>
        </div>
      );
    }

    const booking: BookingLine = _.get(item, 'booking');
    return (
      <div className="pms-room-item__tooltip">
        <div className="pms-room-item__tooltip-header">
          <div className="pms-room-item__tooltip-header__customer">
            <span>{booking.partner_name}</span>
          </div>
          <div className="pms-room-item__tooltip-header__timeline">
            {booking?.check_in && (
              <div className="flex items-center" style={{ gap: '4px' }}>
                <img src={IconArrival} alt="arrival" />
                <span>{toLocalTime(booking?.check_in, 'DD/MM HH:mm')}</span>
              </div>
            )}
            {booking?.check_out && (
              <div className="flex items-center" style={{ gap: '4px' }}>
                <img src={IconDeparture} alt="departure" />
                <span>{toLocalTime(booking?.check_out, 'DD/MM HH:mm')}</span>
              </div>
            )}
          </div>
        </div>
        {booking?.note && (
          <div className="pms-room-item__tooltip-body">
            <img src={IconNote} alt="note" />
            <span>{booking?.note}</span>
          </div>
        )}
        <div className="pms-room-item__tooltip-footer">
          <div className="pms-room-item__tooltip-footer__number-customer">
            <div className="flex items-center" style={{ gap: '2px' }}>
              <img src={IconUser} alt="adults" />
              <span>{booking?.adult}</span>
            </div>
            <div className="flex items-center" style={{ gap: '2px' }}>
              <img src={IconChildren} alt="children" />
              <span>{booking?.child}</span>
            </div>
          </div>
          <div className="pms-room-item__tooltip-footer__booking-id">
            <span>#{booking?.booking_id}</span>
          </div>
        </div>
      </div>
    );
  };

  const handleTouchStart = (e: TouchEvent) => {
    const touch = e.touches[0];
    setChangePositionX(touch.clientX); // Set X position from touch event
    setChangePositionY(touch.clientY); // Set Y position from touch event

    setIsOpenDropdown(true); // Open dropdown
  };

  return (
    <Dropdown
      trigger={['click']}
      menu={{
        items: getMenuTimelineItem(item),
        onClick: onClickMenu
      }}
      overlayClassName="action-timeline action-timeline--auto"
      destroyPopupOnHide
      overlayStyle={{
        top: changePositionY,
        left: changePositionX
      }}
      open={isOpenDropdown}
      onOpenChange={setIsOpenDropdown}
    >
      <div
        {...itemProps}
        ref={boxRef}
        className="rct-item"
        onTouchStart={handleTouchStart}
        data-tooltip-content={ReactDOMServer.renderToStaticMarkup(renderTooltip(item))}
      >
        {/* {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''} */}

        <div
          className="rct-item-content"
          data-tooltip-id="menu-tooltip"
          style={{ maxHeight: `${itemContext.dimensions.height}` }}
        >
          {itemContext.title}
        </div>

        {/* {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''} */}
      </div>
    </Dropdown>
  );
}

export default TimelineItem;
