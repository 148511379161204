import { CustomMenuItem } from 'helper/type/action.type';
import { getActionsPermission } from 'helper/permission.action';

export const useFilterActions = (
  actionList: CustomMenuItem[],
  permissions: Record<string, boolean>,
  hasPermission: (permissionCode: string) => boolean
) => {
  return getActionsPermission(actionList, permissions, hasPermission);
};
