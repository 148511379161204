import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Input, Modal, notification, Row, Select } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { moveSaleOrder } from 'services/api/module/booking.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import { ValueSelectedItemsOfTransactionSearchBooking } from 'services/api/type/booking.type';
import useModal from 'stores/useModal';
import CashierModal from 'components/modal/transaction-modal/transaction-search-booking';
import classNames from 'classnames';

function MoveSaleOrder() {
  const [form] = Form.useForm();
  const { Search } = Input;

  const [selectedItems, setSelectedItems] =
    useState<ValueSelectedItemsOfTransactionSearchBooking>();

  const {
    moveSaleOrder: { isOpenMoveSaleOrder, bookingLineId, guest_id, guest_order_id },
    closeMoveSaleOrder,
    setIsOpenTransactionSearchBooking
  } = useModal();

  const { data: bookingLineDetailForMoveFrom } = useGetBookingDetail(bookingLineId);
  const { data: bookingLineDetailForMoveTo } = useGetBookingDetail(
    selectedItems?.booking_line_id || bookingLineId
  );

  const { mutateAsync: mutateMoveSaleOrder, isPending } = useMutation({
    mutationFn: (params: any) =>
      moveSaleOrder({
        guest_id: params.guest_id,
        guest_order_id: guest_order_id
      })
  });

  useEffect(() => {
    const guestId = bookingLineDetailForMoveTo?.booking_line_guests.find(
      item => item.id === guest_id || item.id === selectedItems?.id
    )?.id;

    form.setFieldsValue({
      booking_line_id: bookingLineDetailForMoveTo?.booking_line_id,
      guest_id: guestId
    });
    // eslint-disable-next-line
  }, [bookingLineDetailForMoveTo]);

  const handleCloseModal = () => {
    closeMoveSaleOrder();
    form.resetFields();
  };

  const onFinish = async (values: any) => {
    try {
      await mutateMoveSaleOrder({
        guest_id: values.guest_id
      });
      closeMoveSaleOrder();
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, guest_id]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_TRANSACTION_HISTORY_LOGS]
      });
      notification.success({
        message: 'Chuyển SO thành công'
      });
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  const handleSearch = () => {
    setIsOpenTransactionSearchBooking(true);
  };

  return (
    <Modal
      title="MOVE SALE ORDER"
      centered
      width={500}
      open={isOpenMoveSaleOrder}
      onCancel={handleCloseModal}
      okText="Lưu"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText="Đóng"
      className={classNames('modal-change-price')}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        autoComplete="off"
        className="move-transaction-form"
      >
        <Card className="move-transaction-card">
          <h3 className="card-title">MOVE FROM</h3>
          <div className="move-transaction-card__body">
            <Row gutter={[16, 8]} className="w-full equal-spacing-row">
              <Col span={7} className="label-column">
                <strong>Guest Name</strong>
              </Col>
              <Col span={17} className="span-column">
                <span className="ant-form-text">
                  {bookingLineDetailForMoveFrom?.booking_line_guests
                    .filter(item => item.id === guest_id)
                    .map(item => item.guest_name)}
                </span>
              </Col>
              <Col span={7} className="label-column">
                <strong>Reservation</strong>
              </Col>
              <Col span={17} className="span-column">
                <span className="ant-form-text">
                  {bookingLineDetailForMoveFrom?.booking_line_id}
                </span>
              </Col>
              <Col span={7} className="label-column">
                <strong>Room</strong>
              </Col>
              <Col span={17} className="span-column">
                <span className="ant-form-text">
                  {bookingLineDetailForMoveFrom?.room_name ||
                    bookingLineDetailForMoveFrom?.room_type_name}
                </span>
              </Col>
            </Row>
          </div>
        </Card>

        <Card className="move-transaction-card">
          <h3 className="card-title">MOVE TO</h3>
          <div className="move-transaction-card__body">
            <Row gutter={[16, 0]}>
              <Col span={7} className="label">
                <p>
                  Reservation <span className="required">*</span>
                </p>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="booking_line_id"
                  rules={[{ required: true, message: 'Reservation is required' }]}
                  className="form-item reduced-space label-align-right"
                >
                  <Search
                    placeholder="Reservation"
                    readOnly
                    onSearch={handleSearch}
                    className="input-size-equal"
                  />
                </Form.Item>
              </Col>
              <Col span={7} className="label">
                <p>
                  Guest Name <span className="required">*</span>
                </p>
              </Col>
              <Col span={17}>
                <Form.Item
                  name="guest_id"
                  rules={[{ required: true, message: 'Guest name is required' }]}
                  className="form-item reduced-space label-align-right"
                >
                  <Select className="input-size-equal">
                    {bookingLineDetailForMoveTo?.booking_line_guests.map(item => (
                      <Select.Option key={item.id} value={item.id} className="select-option">
                        {item.guest_name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Card>
        <CashierModal setSelectedItems={setSelectedItems} />
      </Form>
    </Modal>
  );
}

export default MoveSaleOrder;
