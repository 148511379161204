import React from 'react';
import { Dropdown, Menu } from 'antd';
import { EditFilled } from '@ant-design/icons';
import { BookingLine } from 'services/api/type/booking.type';
import { PermissionActionTypes } from 'helper/type/action.type';
import IBButton from 'helper/permission.action';

export const renderRoomsDropdown = (
  record: BookingLine,
  availableRooms?: any[],
  onClickEdit?: (bookingLineId: number, selectedRoomId?: number) => void,
  setRangeDate?: any
) => {
  let newCount: number = 0;
  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    newCount += 1;

    if (newCount === 2) {
      setRangeDate(['', '']);
      newCount = 0;
    } else if (onClickEdit) {
      onClickEdit(record.booking_line_id);
    }
  };

  return (
    <Dropdown
      onOpenChange={open => {
        if (!open) {
          newCount += 1;
        }
      }}
      overlay={
        <Menu
          style={{
            maxHeight: '270px',
            overflowY: 'auto',
            width: '150px'
          }}
        >
          {availableRooms?.map(room => (
            <Menu.Item
              key={room.value}
              disabled={room.disabled}
              onClick={({ domEvent }) => {
                domEvent.stopPropagation();
                if (onClickEdit) {
                  onClickEdit(record.booking_line_id, room.value);
                }
              }}
            >
              {room.label}
            </Menu.Item>
          ))}
        </Menu>
      }
      trigger={['click']}
    >
      <IBButton
        permission={PermissionActionTypes.CHANGE_ROOM}
        icon={<EditFilled />}
        onClick={handleButtonClick}
        style={{ fontSize: '16px', marginLeft: '8px' }}
      />
    </Dropdown>
  );
};
